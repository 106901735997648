import http from "../../http-common";

const getAll = () => {
  return http.get("/advertdetailpayment");
};

const getAllNotVerifiedAdvertDetailPayment = () => {
  return http.get("/advertdetailpayment/verifypayment");
};



const getAllPendingCount = () => {
  return http.get("/advertdetailpayment/pendingcnt");
};

const getAllPaymentCount = () => {
  return http.get("/advertdetailpayment/pymntcnt");
};

const getAllPaymentStats = () => {
  return http.get("/advertdetailpayment/paymentstats");
};

const get = id => {
  return http.get(`/advertdetailpayment/${id}`);
};

const create = data => {
  return http.post("/advertdetailpayment", data);
};

const update = (id, data) => {
  return http.put(`/advertdetailpayment/${id}`, data);
};

const updateStatus = (data) => {
  return http.put(`/advertdetailpayment/status/${data.id}`,data);
};

const remove = id => {
  return http.delete(`/advertdetailpayment/${id}`);
};


const advertdetailpaymentService = {
  getAll,
  get,
  create,
  update,
  remove,
  getAllPaymentCount,
  getAllPendingCount,
  getAllPaymentStats,
  updateStatus,
  getAllNotVerifiedAdvertDetailPayment
};

export default advertdetailpaymentService;