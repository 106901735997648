import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { vericationheader } from '../database/TableHeaders'
import TableOwnerBody from '../components/TableOwnerBody'
import Table from '../components/Table'
import CustomSkelton from '../components/CustomSkelton'
import { useDispatch, useSelector } from 'react-redux'
import { getAllVerifiedOwner } from '../api/features/profileSlice'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '18em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function Owner() {
  const dispatch = useDispatch()

  var { verifiedowner, isLoading } = useSelector((state) => ({
    ...state.profile,
  }))


  useEffect(() => {
    dispatch(getAllVerifiedOwner())
  }, [])

  const classes = useStyles()
  const tableOwnerBody = (data) => <TableOwnerBody rowData={data} />

  const boardimData = verifiedowner.map((o) => ({ ...o }))
  return (


     <Container maxWidth="xl">
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Grid item>
                <CustomSkelton />
              </Grid>
            </>
          ) : (
            <>
              <Grid item style={{ marginBottom: '2em' }}>
                <Typography variant="h4">Owner Details </Typography>
              </Grid>
              <Grid item>
                <Table
                  data={boardimData}
                  columns={vericationheader}
                  childs={tableOwnerBody}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid> 
    </Container>
  )
}

export default Owner
