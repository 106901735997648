import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  Typography,
  Container,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { tableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAdvertPlan,
  deleteAdvertPlan,
  createAdvertPlan,
} from '../api/features/advertPlanSlice'
import { paymentHeader } from '../database/TableHeaders'
import ImageZoom from './ImageZoom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorImg from '../assets/error.png'
import CustomSkelton from './CustomSkelton'
import {
  getAllNotVerifiedAdvertDetailPayment,
  updatePaymentStatus,
} from '../api/features/advertDetailPaymentSlice'
import CustomSnackBar from './CustomSnackBar'
import { Send } from '@material-ui/icons'
import { sendToDevice } from '../api/features/cloudMessagingSlice'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginTop: '1em',
    marginLeft: '6em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function PaymentVerificationTable() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [flag, setFlag] = useState(false)
  const [flag2, setFlag2] = useState(false)
  const dispatch = useDispatch()

  const { notverifypayments, isLoading } = useSelector((state) => ({
    ...state.advertdetailpayment,
  }))

  useEffect(() => {
    dispatch(getAllNotVerifiedAdvertDetailPayment())
    setFlag(false)
  }, [flag])

  const sendMessage = async (e, id) => {
    e.preventDefault()
    const payload = {
      id: '',
      title: 'Payment Verification Alert',
      msg: '',
    }
    payload.id = id
    payload.msg = document.getElementById('msg').value
    await dispatch(sendToDevice(payload))
    document.getElementById('msg').value = ''
    setFlag2(true)
  }

  if (!Array.isArray(notverifypayments)) {
    notverifypayments = [notverifypayments]
  }

  const studentData = notverifypayments.map((o) => ({ ...o }))

  return (
    <Container maxWidth="xl">
      {flag ? (
        <CustomSnackBar msg="verification Success" isOpen={flag} />
      ) : null}
      {flag2 ? (
        <CustomSnackBar msg="Message sent Successfully" isOpen={flag} />
      ) : null}
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Grid item>
                <CustomSkelton />
              </Grid>
            </>
          ) : (
            <>
              <Grid item style={{ marginBottom: '2em' }}>
                <Typography variant="h4">Payment Verification </Typography>
              </Grid>
              <Grid item>
                <MaterialTable
                  tableIcons={tableIcons}
                  style={{ width: matchesSM ? '25em' : '95em' }}
                  title={''}
                  columns={paymentHeader.map((c) => ({
                    ...c,
                    tableData: undefined,
                  }))}
                  data={studentData}
                  detailPanel={(rowData) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-evenly"
                        style={{ margin: '1em' }}
                      >
                        <Grid
                          item
                          container
                          justifyContent={'space-evenly'}
                          alignItems="center"
                          alignContent="center"
                        >
                          <Grid item>
                            <ImageZoom
                              url={
                                rowData.receipt_image_url == null
                                  ? ErrorImg
                                  : rowData.receipt_image_url
                              }
                              caption={'Front View'}
                              flag={true}
                            />
                          </Grid>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Grid item style={{ marginTop: '2em' }}>
                            <Button
                              disabled={rowData.receipt_image_url == null}
                              variant="contained"
                              endIcon={<CheckCircleIcon />}
                              color="primary"
                              onClick={() => {
                                dispatch(
                                  updatePaymentStatus({
                                    id: rowData.id,
                                    status: 1,
                                  }),
                                )

                                dispatch(getAllNotVerifiedAdvertDetailPayment())
                                setFlag(true)
                              }}
                            >
                              Verify here
                            </Button>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ marginLeft: '1em' }}
                          >
                            {/* <form onSubmit={sendMessage}> */}
                            <Grid item xs={9}>
                              <TextField
                                id="msg"
                                label="Custom Message"
                                placeholder="send a message..."
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                // onChange={onChangeInput}
                                name="msg"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Button
                                type="submit"
                                variant="contained"
                                endIcon={<Send />}
                                style={{ margin: '1em 1em' }}
                                color="primary"
                                onClick={(e) =>
                                  sendMessage(e, rowData.Advertisement.Owner.id)
                                }
                              >
                                Send
                              </Button>
                            </Grid>
                            {/* </form> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }}
                  options={{
                    headerStyle: {
                      backgroundColor: '#01579b',
                      color: 'white',
                    },
                    paging: false,
                    selection: true,
                    grouping: true,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PaymentVerificationTable
