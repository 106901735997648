import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import Layout from '../screens/Layout'
import Cookies from 'universal-cookie'

const RequireAuth = ({ allowedRoles }) => {
  const cookies = new Cookies()
  const auth = cookies.get('auth')
  const location = useLocation()

  return auth == null ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : allowedRoles.includes(auth.user_data.role_id) ? (
    <Layout />
  ) : auth?.token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default RequireAuth
