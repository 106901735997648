import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import ClearIcon from '@material-ui/icons/HighlightOff'
import AddLocationIcon from '@material-ui/icons/GpsFixed'
import { mapStyles } from './mapStyles'
import { useTheme } from '@material-ui/styles'
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  InfoWindow,
  Circle,
  useLoadScript 
} from '@react-google-maps/api'
import { useRef, useState } from 'react'
import CustomSkelton from '../CustomSkelton'

const university = {
  lat: 5.937795,
  lng: 80.57611,
}

const libraries = ['places']

function MainMap({ locations }) {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: 'AIzaSyAODHNR0-ODunUFFSyz2pk_FWiukwoTFV8',
  //   libraries: ['places'],
  // })
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      process.env.GOOGLE_MAPS_API_KEY ??
      'AIzaSyAODHNR0-ODunUFFSyz2pk_FWiukwoTFV8',
    libraries,
  })

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [markers, setMarkers] = useState([locations])
  const [selected, setSelected] = useState(null)

  if (!isLoaded) {
    return <CustomSkelton/>
  }

  async function calculateRoute(location) {
    // eslint-disable-next-line no-undef
    var start = new google.maps.LatLng(location.lat, location.lng)
    // eslint-disable-next-line no-undef
    var end = new google.maps.LatLng(5.937795, 80.57611)

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: start,
      destination: end,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })

    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  function clearRoute() {
    setDirectionsResponse(null)
    setDistance('')
    setDuration('')
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      // style={{ height: '75vh', width: '150vh' }}
    >
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h6">Distance: {distance} </Typography>
        <Typography variant="h6">Duration: {duration} </Typography>

        <Tooltip title="university">
          <IconButton
            //   color="secondary"
            aria-label="add an alarm"
            onClick={() => {
              map.panTo(university)
              map.setZoom(15)
            }}
          >
            <AddLocationIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="clear">
          <IconButton
            //   color="primary"
            aria-label="add to shopping cart"
            onClick={() => {
              clearRoute()
              map.setZoom(11)
            }}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item container style={{ height: '65vh', width: matchesSM ? '50vh':'150vh' }}>
        <GoogleMap
          center={university}
          zoom={11.5}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            // styles: mapStyles,
            // disableDefaultUI: true,
            // zoomControl: true,
          }}
          onLoad={(map) => setMap(map)}
        >
          {markers[0].map((mkr, index) => (
            <Marker
              key={index}
              position={{ lat: mkr[0].lat, lng: mkr[0].lng }}
              onClick={() => {
                setSelected(mkr[0])
              }}
            />
          ))}

          {selected ? (
            <InfoWindow
              position={{ lat: selected.lat, lng: selected.lng }}
              onCloseClick={() => {
                setSelected(null)
              }}
              zIndex={1500}
            >
              <Grid container direction="column" alignItems="center">
                <Typography variant="h6"></Typography>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      calculateRoute({ lat: selected.lat, lng: selected.lng })
                      setSelected(null)
                    }}
                  >
                    Get Route
                  </Button>
                </Grid>
              </Grid>
            </InfoWindow>
          ) : null}

          <Marker position={university} label="Ruhuna University" />
          {directionsResponse && (
            <DirectionsRenderer
              directions={directionsResponse}
              options={{
                polylineOptions: {
                  strokeOpacity: 1,
                  strokeColor: '#0b9fb9',
                  strokeWeight: 5,
                  zIndex: 5,
                },
              }}
            />
          )}
          <Circle center={university} radius={5000} options={closeOptions} />
          <Circle center={university} radius={10000} options={middleOptions} />
          <Circle center={university} radius={15000} options={farOptions} />
        </GoogleMap>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: '2em', marginTop: '1em' }}
      >
        <Grid item>
          <Grid item>
            <Typography variant="subtitle1">
              {' '}
              <b>5 km range</b>{' '}
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                width: '70px',
                height: '10px',
                backgroundColor: '#8BC34A',
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="subtitle1">
              {' '}
              <b>10 km range</b>
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                width: '80px',
                height: '10px',
                backgroundColor: '#AFBBFE',
              }}
            />
          </Grid>
        </Grid>
        <Grid item >
          <Grid item>
            <Typography variant="subtitle1">
              <b>15 km range</b>
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                width: '80px',
                height: '10px',
                backgroundColor: '#AFFEC6',
              }}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
}
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.25,
  strokeColor: '#8BC34A',
  fillColor: '#FEAFAF',
}
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.25,
  // strokeColor: "#FBC02D",
  strokeColor: '#AFBBFE',
  fillColor: '#FBC02D',
}
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.25,
  strokeColor: '#AFFEC6',
  fillColor: '#8BC34A',
}

export default MainMap