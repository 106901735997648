import http from '../../http-common'

const getAll = () => {
  return http.get('/registration')
}

const getRegDateCount = () => {
  return http.get('/registration/regcount')
}

const getStudentCount = () => {
  return http.get('/registration/studentcount')
}

const getAllNotVerified = () => {
  return http.get('/registration/verifystudent')
}

const get = (id) => {
  return http.get(`/registration/${id}`)
}

const create = (data) => {
  return http.post('/registration', data)
}

const update = (id, data) => {
  return http.put(`/registration/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/registration/${id}`)
}

const RegistrationService = {
  getAll,
  get,
  create,
  update,
  remove,
  getRegDateCount,
  getStudentCount,
  getAllNotVerified,
}

export default RegistrationService
