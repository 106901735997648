import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { messageHeader } from '../database/TableHeaders'
import TableMessageBody from '../components/TableMessageBody'
import Table from '../components/Table'
import { Send } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllUsers, getOwners, getStudents } from '../api/features/userSlice'
import { bulkSendToDevice } from '../api/features/cloudMessagingSlice'
import { useState } from 'react'
import CustomSnackBar from '../components/CustomSnackBar'
import { createMessage, getAllMessages } from '../api/features/messageSlice'
import CustomSkelton from '../components/CustomSkelton'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '22em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  },
}))

function Message() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [val, setVal] = useState()
  const [flag, setFlag] = useState(false)

  const tableMessageBody = (data) => <TableMessageBody rowData={data} />

  var { students, users, owners } = useSelector((state) => ({
    ...state.user,
  }))

  var { messages , isLoading } = useSelector((state) => ({
    ...state.message,
  }))

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getOwners())
    dispatch(getStudents())
    dispatch(getAllMessages())
  }, [dispatch])

  const sendStudentMessage = async () => {
    const payload = {
      data: students,
      title: document.getElementById('subject').value,
      msg: document.getElementById('msg').value,
    }
    await dispatch(bulkSendToDevice(payload))
  }

  const sendOwnerMessage = async () => {
    const payload = {
      data: owners,
      title: document.getElementById('subject').value,
      msg: document.getElementById('msg').value,
    }
    await dispatch(bulkSendToDevice(payload))
  }

  const sendAudienceMessage = async () => {
    const payload = {
      data: users,
      title: document.getElementById('subject').value,
      msg: document.getElementById('msg').value,
    }
    await dispatch(bulkSendToDevice(payload))
  }

  const sendMessage = (e) => {
    e.preventDefault()
    if (val != 0) {
      if (val == 10) {
        sendStudentMessage()
      } else if (val == 20) {
        sendOwnerMessage()
      } else {
        sendAudienceMessage()
      }

      dispatch(
        createMessage({
          message: document.getElementById('msg').value,
          subject: document.getElementById('subject').value,
          audience_id: val,
        }),
      )

      setVal(0)
      setFlag(true)
      document.getElementById('msg').value = ''
      document.getElementById('subject').value = ''
    }
  }
  const messageData = messages.map((o) => ({ ...o }))
  return (
    <Grid
      container
      direction="column"
      // justifyContent="center"
      // alignItems="center"
      className={classes.content}
    >
      {isLoading ? (
        <>
          <Grid item>
            <CustomSkelton />
          </Grid>
        </>
      ) : (
        <>
          {flag ? (
            <CustomSnackBar msg="Messgae sent Successfully" isOpen={flag} />
          ) : null}

          <Grid item style={{ marginBottom: '2em' }}>
            <Typography variant="h4">Message Center </Typography>
          </Grid>
          <Grid item style={{ marginBottom: '2em' }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: '15em' }}
            >
              <InputLabel htmlFor="msgbox">Send</InputLabel>
              <Select
                native
                value={val}
                onChange={(e) => setVal(e.target.value)}
                label="Age"
                inputProps={{
                  name: 'msgbox',
                  id: 'msgbox',
                }}
              >
                <option aria-label="None" value={0} />
                <option value={1}>Student</option>
                <option value={2}>Owner</option>
                <option value={3}>All</option>
              </Select>
            </FormControl>
          </Grid>

          <Grid item style={{ marginBottom: '2em' }}>
            <Grid
              item
              container
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <TextField
                  id="subject"
                  label="Subject"
                  // placeholder="subject"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="subject"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginBottom: '2em' }}>
            <Grid
              item
              container
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={9}>
                <TextField
                  id="msg"
                  label="Message"
                  // placeholder="send a message..."
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="msg"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<Send />}
                  style={{ margin: '1em 1em' }}
                  color="primary"
                  onClick={(e) => sendMessage(e)}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginBottom: '2em' }}>
            <Typography variant="h4">Message History </Typography>
          </Grid>
          <Grid item>
            <Table
              data={messageData}
              columns={messageHeader}
              childs={tableMessageBody}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Message
