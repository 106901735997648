
import axios from "axios";

const baseURL= "https://boardima-backend-39as.onrender.com/"
// const baseURL= "http://localhost:4000/"

export default axios.create({
  baseURL: baseURL,
  headers: {
    'Content-type': 'application/json',
  },
  // withCredentials: true,
})

// axios.interceptors.request.use(
//   (config) => {
//     const auth = localStorage.getItem('access_token')

//     console.log(auth)

//     const { origin } = new URL(config.url)
//     const allowedOrigins = [baseURL]
//     if (allowedOrigins.includes(origin)) {
//       config.headers.authorization = `Bearer ${auth}`
//     }
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   },
// )
