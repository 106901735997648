import { ThemeProvider } from '@material-ui/styles'
import { useRoutes } from 'react-router-dom'
import theme from './ui/theme'
import Routings from './routes'
import { useEffect } from 'react'

function App() {

  return (

      <ThemeProvider theme={theme}><Routings/></ThemeProvider>
  )
}

export default App
