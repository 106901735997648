
import { Box } from '@material-ui/core'
import React from 'react'

function ImageAvatar({img}) {
  return (
    <Box
      component="img"
      sx={{
        height: '15em',
        width: '15em',
        borderRadius: '5em',
      }}
      alt="The house from the offer."
      src={img}
    />
  )
}

export default ImageAvatar
