import http from "../../http-common";

const getAllTotalEarnings = () => {
  return http.get("/custom/totearnings");
};

const getAllPendingEarnings =() => {
  return http.get("/custom/totpendingearnings");
};

const getAllSubViceAmount = () => {
  return http.get("/custom/subviceamount");
};

const UserService = {
  getAllPendingEarnings,
  getAllSubViceAmount,
  getAllTotalEarnings
};

export default UserService;