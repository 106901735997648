import http from "../../http-common";

const getAll = () => {
  return http.get("/advertisement");
};

const getAllNotVerified = () => {
  return http.get("/advertisement/notverifiedadvert");
};

const getAllVerified = () => {
  return http.get("/advertisement/verifiedadvert");
};

const get = id => {
  return http.get(`/advertisement/${id}`);
};

const create = data => {
  return http.post("/advertisement", data);
};

const update = (id, data) => {
  return http.put(`/advertisement/${id}`, data);
};

const remove = id => {
  return http.delete(`/advertisement/${id}`);
};


const AdvertisementService = {
  getAll,
  get,
  create,
  update,
  remove,
  getAllNotVerified,
  getAllVerified,
};

export default AdvertisementService;