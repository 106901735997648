import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdvertisementService from "../services/advertisementService";

export const getAllAdertisements = createAsyncThunk(
  "advertisement/getAll",
  async () => {
    const res = await AdvertisementService.getAll();
    return res.data;
  }
);


export const getAllNotVerifiedAdertisements = createAsyncThunk(
  "advertisement/getAllNotVerified",
  async () => {
    const res = await AdvertisementService.getAllNotVerified();
    return res.data;
  }
);

export const getAllVerifiedAdertisements = createAsyncThunk(
  "advertisement/getAllVerified",
  async () => {
    const res = await AdvertisementService.getAllVerified();
    return res.data;
  }
);


const advertisementSlice = createSlice({
  name: 'advertisement', 
  initialState: {
    advertisements: [],
    notVerifyadvertisements: [],
    verifyadvertisements: [],
    isLoading: false,
  },
  extraReducers: {
    [getAllAdertisements.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllAdertisements.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.advertisements = payload['data'];
    },
    [getAllAdertisements.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllNotVerifiedAdertisements.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotVerifiedAdertisements.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notVerifyadvertisements = payload['data'];
    },
    [getAllNotVerifiedAdertisements.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllVerifiedAdertisements.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllVerifiedAdertisements.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.verifyadvertisements = payload['data'];
    },
    [getAllVerifiedAdertisements.rejected]: (state) => {
      state.isLoading = false;
    },

  },
})


export default advertisementSlice.reducer
