import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdvertDetailPaymentService from "../services/advertDetailPaymentService";

export const getAllAdvertDetailPaymentsss = createAsyncThunk(
  "advertdetailpayment/getAll",
  async () => {
    const res = await AdvertDetailPaymentService.getAll();
    return res.data;
  }
);

export const getAllNotVerifiedAdvertDetailPayment = createAsyncThunk(
  "advertdetailpayment/verifypayment",
  async () => {
    const res = await AdvertDetailPaymentService.getAllNotVerifiedAdvertDetailPayment();
    return res.data;
  }
);

export const getAllPendingCount = createAsyncThunk(
  "advertdetailpayment/pendingcnt",
  async () => {
    const res = await AdvertDetailPaymentService.getAllPendingCount();
    return res.data;
  }
);

export const getAllPaymentCount = createAsyncThunk(
  "advertdetailpayment/pymntcnt",
  async () => {
    const res = await AdvertDetailPaymentService.getAllPaymentCount();
    return res.data;
  }
);

export const getAllPaymentStats = createAsyncThunk(
  "advertdetailpayment/paymentstats",
  async () => {
    const res = await AdvertDetailPaymentService.getAllPaymentStats();
    return res.data;
  }
);

export const updatePaymentStatus = createAsyncThunk(
  "advertdetailpayment/updateStatus",
  async (data ) => {
    const res = await AdvertDetailPaymentService.updateStatus(data);
    return res.data;
  }
);

const advertDetailPaymentSlice = createSlice({
  name: 'advertdetailpayment', 
  initialState: {
    adevertdetailpayments: [],
    pendingCount:0,
    paymentCount:[],
    paymentStats:[],
    notverifypayments:[],
    isLoading: false,
    msg : null
  },
  extraReducers: {
    [getAllAdvertDetailPaymentsss.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllAdvertDetailPaymentsss.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.adevertdetailpayments = payload['data'];
    },
    [getAllAdvertDetailPaymentsss.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllPendingCount.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllPendingCount.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.pendingCount = payload['data'];
    },
    [getAllPendingCount.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllPaymentCount.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllPaymentCount.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.paymentCount = payload['data'];
    },
    [getAllPaymentCount.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllPaymentStats.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllPaymentStats.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.paymentStats = payload['data'][0];
    },
    [getAllPaymentStats.rejected]: (state) => {
      state.isLoading = false;
    },
    [updatePaymentStatus.pending]: (state) => {
      state.isLoading = true;  
    },
    [updatePaymentStatus.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      console.log(payload)
      state.msg = payload['data'];
    },
    [updatePaymentStatus.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllNotVerifiedAdvertDetailPayment.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotVerifiedAdvertDetailPayment.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notverifypayments = payload['data'];
    },
    [getAllNotVerifiedAdvertDetailPayment.rejected]: (state) => {
      state.isLoading = false;
    },
  },
})


export default advertDetailPaymentSlice.reducer
