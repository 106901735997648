import { Grid } from '@material-ui/core'
import React from 'react'
import MainHeader from '../components/MainHeader'
import {  Outlet } from 'react-router-dom'

function Layout() {
  return (
    <Grid>
        <MainHeader/>
        <Outlet/>
    </Grid>
  )
}

export default Layout