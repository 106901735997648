import React, { useState , useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../auth/hooks/useAuth'
import http from '../http-common'
import logo from '../assets/psw_clipart.png'
import img2 from '../assets/psw_clipart.png'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { getAccessToken } from '../api/features/tokenSlice'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: 'white',
    fontFamily: 'Poppins-Regular',
  },

  paper: {
    //  margin: theme.spacing(10,6),
    marginRight: '40px',
    marginLeft: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignitems: 'center',
  },

  avatar: {
    marginLeft: '30px',
  },

  form: {
    width: '100%',
    margintop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    bgcolor: 'primary',
  },
  forget: {
    textAlign: 'right',
  },
  get: {
    textAlign: 'center',
  },
}))

function Login() {
  const classes = useStyles()
  const cookies = new Cookies()

  const { login } = useAuth()
  const navigate = useNavigate()
  const [user, setUser] = useState({
    email: '',
    pwd: '',
  })

  const [isError, seIstError] = useState(false)
  const [usrdata, setUsrsData] = useState([])

  const dispatch = useDispatch()

  const { loginData, isLoing } = useSelector((state) => ({
    ...state.user,
  }))

  const { access_token, isLoding } = useSelector((state) => ({
    ...state.token,
  }))

  const loginSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await http.post('/login', user)
      if (response.data.data != null) {
        login(response.data.data)
        navigate('/dashboard/home')
        
      }
    } catch (error) {
      console.log(error)
      seIstError(true)
    }
  }

  const onChangeInput = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  useEffect(() => {
    // if (cookies.get('auth') != null) {
    //   dispatch(getAccessToken(cookies.get('role_id')))
    //   navigate('/dashboard/home')
    // }else{
    //   navigate('/')
    // }
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        component="main"
        alignItems="center"
        justifyContent="center"
      >
        <CssBaseline />
        <Grid item xs={12} sm={4} md={8}>
          <img
            src={logo}
            width="80%"
            alt=""
            height="80%"
            elevation={0}
            backgroundrepeat="no-repeat"
            backgroundsize="cover"
            backgroundposition="center"
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={0} square>
          <img src={img2} alt="" className={classes.avatar} height={50} />
          <div className={classes.paper}>
            <Typography component="h2" variant="h5" color="primary">
              Login
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={loginSubmit}
              method={'POST'}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onChangeInput}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="pwd"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onChangeInput}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                textalign="left"
              >
                LogIn
              </Button>

              {isError ? (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginBottom: '1em' }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: 'red', fontWeight: 'bold' }}
                  >
                    * Username or Password Incorrect !
                  </Typography>
                </Grid>
              ) : null}

              <Grid container className={classes.forget}>
                <Grid item xs>
                  <Button
                    variant="text"
                    style={{ color: '#0b72B9', textTransform: 'lowercase' }}
                    component={Link}
                    to="/signup"
                  >
                    Forgot password ?
                  </Button>
                </Grid>
                <Grid container className={classes.get}>
                  <Grid item xs>
                    <Typography variant="body2">
                      Dont't have an account
                      <Button
                        variant="text"
                        style={{ color: '#0b72B9', textTransform: 'lowercase' }}
                        component={Link}
                        to="/signup"
                      >
                        Register ?
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
