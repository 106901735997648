import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { tableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAdvertPlan,
  deleteAdvertPlan,
  createAdvertPlan,
} from '../api/features/advertPlanSlice'

function Table({ data, columns, childs, flag = true }) {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useDispatch()

  const [rows, setRows] = useState(data == null ? [] : data)

  return (
    <MaterialTable
      tableIcons={tableIcons}
      style={{ width: matchesSM ? '25em' : '95em' }}
      title={''}
      columns={columns.map((c) => ({ ...c, tableData: undefined }))}
      data={rows}
      detailPanel={
        flag
          ? (rowData) => {
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent={'center'}
                >
                  {childs(rowData)}
                </Grid>
              )
            }
          : null
      }
      // onTreeExpandChange
      editable={
        !flag
          ? {
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  const updatedRows = [...rows, newData]
                  setTimeout(() => {
                    setRows(updatedRows)
                    const testData = {
                      name: newData.name,
                      cost: newData.cost,
                      duration: newData.duration,
                    }
                    dispatch(createAdvertPlan(testData))
                    resolve()
                  }, 5000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  const index = oldData.tableData.id
                  const updatedRows = [...rows]
                  updatedRows[index] = newData
                  setTimeout(() => {
                    setRows(updatedRows)
                    const testData = {
                      name: newData.name,
                      cost: newData.cost,
                      duration: newData.duration,
                    }
                    dispatch(updateAdvertPlan(newData.id, testData))
                    resolve()
                  }, 5000)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  console.log(oldData.id)
                  const index = oldData.tableData.id
                  const updatedRows = [...rows]
                  updatedRows.splice(index, 1)
                  setTimeout(() => {
                    setRows(updatedRows)
                    dispatch(deleteAdvertPlan(oldData.id))
                    resolve()
                  }, 5000)
                }),
            }
          : null
      }
      options={{
        headerStyle: {
          backgroundColor: '#01579b',
          color: 'white',
        },
        paging: true,
        selection: true,
        grouping: true,
      }}
    />
  )
}

export default Table
