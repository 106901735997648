import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import ImageAvatar from './ImageAvatar'
import { Send } from '@material-ui/icons'

function TableMessageBody({ rowData }) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={'center'}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems={'center'}
          style={{ margin: '1em 1em 1em 5em' }}
        >
          <>
            <Grid item style={{ margin: '0 0 0 1em' }}>
              <Typography variant="h6">{rowData.subject}</Typography>
            </Grid>
            <Grid item style={{ margin: '1em' }}>
              <Typography variant="body2">{rowData.message}</Typography>
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TableMessageBody
