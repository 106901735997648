import http from "../../http-common";

const get = id => {
  return http.get(`/token/access_token/${id}`);
};

const TokenService = {
  get
};

export default TokenService;