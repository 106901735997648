import http from '../../http-common'

const getAll = () => {
  return http.get('/message')
}

const create = (data) => {
  return http.post('/message', data)
}

const MessageService = {
  getAll,
  create,
}

export default MessageService
