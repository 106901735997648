import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

import UserReducer from './api/features/userSlice'
import AdvertisementReducer from './api/features/advertisementSlice'
import RegistrationReducer from './api/features/registrationSlice'
import BoardimPlaceReducer from './api/features/boardimPlaceSlice'
import AdvertDetailPaymentReducer from './api/features/advertDetailPaymentSlice'
import CustomReducer from './api/features/customSlice'
import BoadimImageReducer from './api/features/boardimImageSlice'
import AdvertPlanReducer from './api/features/advertPlanSlice'
import ProfileReducer from './api/features/profileSlice'
import cloudMessageReducer from './api/features/cloudMessagingSlice'
import TokenReducer from './api/features/tokenSlice'
import MessageReducer from './api/features/messageSlice'

// const reducers = combineReducers({
//   user: UserReducer,
//   advertisement: AdvertisementReducer,
//   registration: RegistrationReducer,
//   boardimplace: BoardimPlaceReducer,
//   advertdetailpayment: AdvertDetailPaymentReducer,
//   custom: CustomReducer,
//   boardimimage: BoadimImageReducer,
//   advertplan : AdvertPlanReducer
// });

const persistConfig = {
  key: 'root',
  storage,
}

// const persistedReducer = persistReducer(persistConfig, reducers);

// export default configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk],
// });

export default configureStore({
  reducer: {
    // user: persistReducer(persistConfig, UserReducer),
    user: UserReducer,
    advertisement: AdvertisementReducer,
    registration: RegistrationReducer,
    boardimplace: persistReducer(persistConfig, BoardimPlaceReducer),
    advertdetailpayment: persistReducer(persistConfig, AdvertDetailPaymentReducer),
    // advertdetailpayment: AdvertDetailPaymentReducer,
    custom: CustomReducer,
    boardimimage: BoadimImageReducer,
    advertplan: AdvertPlanReducer,
    profile: ProfileReducer,
    fcm: cloudMessageReducer,
    token: TokenReducer,
    message: MessageReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})
