import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RegistrationService from "../services/registrationService";

export const getAllRegistrations = createAsyncThunk(
  "registration/getAll",
  async () => {
    const res = await RegistrationService.getAll();
    return res.data;
  }
);

export const getAllNotVerifiedStudents = createAsyncThunk(
  "registration/getAllNotVerified",
  async () => {
    const res = await RegistrationService.getAllNotVerified();
    return res.data;
  }
);


export const getRegDateCount = createAsyncThunk(
  "registration/getRegDateCount",
  async () => {
    const res = await RegistrationService.getRegDateCount();
    return res.data;
  }
);

export const getStudentCount = createAsyncThunk(
  "registration/getStudentCount",
  async () => {
    const res = await RegistrationService.getStudentCount();
    return res.data;
  }
);


const registrationSlice = createSlice({
  name: 'registration', 
  initialState: {
    registrations: [],
    notverifyregistrations:[],
    regDateArr : [] ,
    studentcount : [] ,
    isLoading: false,
  },
//   reducers: {
//     getStudents: (state, action) => {
//       state.students = state.registrations.filter((item) => item.Role.name === 'student')
//     },
//     getOwners: (state, action) => {
//       state.owners = state.registrations.filter((item) => item.Role.name === 'boardim owner')
//     },
//   },
  extraReducers: {
    [getAllRegistrations.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllRegistrations.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.registrations = payload['data'];
    },
    [getAllRegistrations.rejected]: (state) => {
      state.isLoading = false;
    },
    [getRegDateCount.pending]: (state) => {
      state.isLoading = true;  
    },
    [getRegDateCount.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.regDateArr = payload['data'];
    },
    [getRegDateCount.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllNotVerifiedStudents.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotVerifiedStudents.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notverifyregistrations = payload['data'];
    },
    [getAllNotVerifiedStudents.rejected]: (state) => {
      state.isLoading = false;
    },
    [getStudentCount.pending]: (state) => {
      state.isLoading = true;  
    },
    [getStudentCount.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.studentcount = payload['data'];
    },
    [getStudentCount.rejected]: (state) => {
      state.isLoading = false;
    },
  },
})

// export const { getStudents, getOwners } = registrationSlice.actions;

export default registrationSlice.reducer
