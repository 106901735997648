import {
  Container,
  Card,
  CardContent,
  Grid,
  TextField,
  Badge,
  Typography,
  Button,
  IconButton,
  Box,
  Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { boardimPlaceHeader } from '../database/TableHeaders'
import TableBoardimBody from '../components/TableBoardimBody'
import Table from '../components/Table'
import CustomSkelton from '../components/CustomSkelton'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileById, updateProfile } from '../api/features/profileSlice'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import useAuth from '../auth/hooks/useAuth'
import Cookies from 'universal-cookie'
import CustomSnackBar from '../components/CustomSnackBar'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '18em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function Profile() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const user_id = cookies.get('user_id')
  const [flag, setFlag] = useState(false)
  const [url, setUrl] = useState(null)

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    tp: '',
    nic: '',
    address: '',
  })

  const { userProfile, isLoading } = useSelector((state) => ({
    ...state.profile,
  }))

  useEffect(() => {
    dispatch(getProfileById(user_id))
    console.log('Cliked')
    user.first_name = userProfile.first_name
    user.last_name = userProfile.last_name
    user.tp = userProfile.tp
    user.nic = userProfile.nic
    user.address = userProfile.address
  }, [dispatch])

  const onChangeInput = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const profileSubmit = async (e) => {
    e.preventDefault()
    try {
      // if (
      //   user.first_name != '' ||
      //   user.first_name != '' ||
      //   user.tp != '' ||
      //   user.address != '' ||
      //   user.nic != ''
      // ) {
      //   console.log(user)
      //   console.log('Inner')
      //   // first_name = userProfile.first_name
      //   // last_name = userProfile.last_name
      //   // tp = userProfile.tp
      //   // nic = userProfile.nic
      //   // address = userProfile.address

      //   // dispatch(updateProfile(user_id, user))
      // } else {
      //   console.log(document.getElementsByName('fname').value)
      //   console.log('Outer')
      // }
      console.log(user)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container maxWidth="xl">
      {flag ? (
        <CustomSnackBar msg="verification Success" isOpen={flag} />
      ) : null}
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Grid item>
                <CustomSkelton />
              </Grid>
            </>
          ) : (
            <Box
              component="form"
              style={{ padding: '2em' }}
              noValidate
              autoComplete="off"
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h4">Edit Profile</Typography>
              </Grid>
              <form>
                <Box
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '5em',
                    paddingBottom: '2em',
                  }}
                >
                  <Badge
                    badgeContent={
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          name="profile"
                          id="profile"
                          onChange={(e) => setUrl(e.target.files[0])}
                        />
                        <PhotoCamera />
                      </IconButton>
                    }
                  >
                    <Avatar
                      src={
                        // url == null
                        //   ? userProfile.user_img_url
                        //   : URL.createObjectURL(url)
                        userProfile.user_img_url
                      }
                      alt="Pabodi Eranda"
                      style={{
                        height: 140,
                        marginBottom: '1em',
                        marginTop: '-3em',
                        width: 140,
                        borderRadius: '90px',
                        borderBottom: 1,
                        borderColor: 'primary.main',
                        objectFit: 'contain',
                      }}
                    />
                  </Badge>
                </Box>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      label="First Name"
                      value={user.first_name}
                      name="first_name"
                      fullWidth
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      label="Last Name"
                      name="last_name"
                      value={user.last_name}
                      fullWidth
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      label="NIC"
                      name="nic"
                      value={user.nic}
                      fullWidth
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      label="Mobile Number"
                      name="tp"
                      value={user.tp}
                      fullWidth
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      required
                      label="Address"
                      name="address"
                      value={user.address}
                      fullWidth
                      onChange={onChangeInput}
                      multiline
                      rows={2}
                    />
                  </Grid>
                </Grid>
                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '1em 0 1em 0',
                    alignItems: 'center',
                  }}
                >
                  <Button variant="outlined">Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: '#1F6FF5',
                      marginLeft: '1em',
                    }}
                    onClick={profileSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Profile
