import http from "../../http-common";

const sendtodevice = data => {
  http.post("/fcm/sendtodevice", data);
};

const bulksendtodevice = data => {
  http.post("/fcm/bulksendtodevice", data);
};

const CloudMessageService = {
  sendtodevice,
  bulksendtodevice
};

export default CloudMessageService;