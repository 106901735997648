import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MessageService from '../services/messageService'

export const getAllMessages = createAsyncThunk('message/getAll', async () => {
  const res = await MessageService.getAll()
  return res.data
})

export const createMessage = createAsyncThunk('message/create', async (msg) => {
  const res = await MessageService.create(msg)
  return res.data
})

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messages: [],
    msgStatus: [],
  },
  reducers: {},
  extraReducers: {
    [getAllMessages.pending]: (state) => {
      state.isLoading = true
    },
    [getAllMessages.fulfilled]: (state, { payload }) => {
       state.isLoading = false
      state.messages = payload['data']
    },
    [getAllMessages.rejected]: (state) => {
      state.isLoading = false
    },
    [createMessage.pending]: (state) => {
      state.isLoading = true
    },
    [createMessage.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.msgStatus = payload['data']
    },
    [createMessage.rejected]: (state) => {
      state.isLoading = false
    },
  },
})

// export const { getStudents, getOwners } = messageSlice.actions

export default messageSlice.reducer
