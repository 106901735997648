import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BoardimImageService from "../services/boardimImageService";

export const getBoardimImageById = createAsyncThunk(
  "boardimimage/get",
  async ( id ) => {
    const res = await BoardimImageService.get(id);
    return res.data;
  }
);

const boardimImageSlice = createSlice({
  name: 'boardimimage', 
  initialState: {
    boardimimages: [],
    isLoading: false,
  },
  extraReducers: {
    [getBoardimImageById.pending]: (state) => {
      state.isLoading = true;  
    },
    [getBoardimImageById.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.boardimimages = payload['data'];
    },
    [getBoardimImageById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
})


export default boardimImageSlice.reducer
