import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function ImageZoom({ url , caption , flag}) {
  return (
  <figure>
  <Zoom>
    <img
      alt="No Preview Available..."
      src={url}
      height={flag ? '250em':'250em'} width={flag ? '400em':'250em'}
    />
  </Zoom>
  <figcaption>{caption}</figcaption>
</figure>
  )
}

export default ImageZoom
