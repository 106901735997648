import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import boradimplaceService from '../services/boardimPlaceService'

export const getBoardimAreaCount = createAsyncThunk(
  'boradimplace/getBoardimAreaCount',
  async () => {
    const res = await boradimplaceService.getRegDateCount()
    return res.data
  },
)

export const getAllBoardimPlaces = createAsyncThunk(
  'boradimplace/getAll',
  async () => {
    const res = await boradimplaceService.getAll()
    return res.data
  },
)

export const activateBoardimPlace = createAsyncThunk(
  'boradimplace/activate',
  async (data) => {
    const res = await boradimplaceService.activate(data)
    return res.data
  },
)

export const getAllNotActivatedBoardings = createAsyncThunk(
  "advertisement/getAllNotActivated",
  async () => {
    const res = await boradimplaceService.getAllNotActivated();
    return res.data;
  }
);

const boradimplaceSlice = createSlice({
  name: 'boradimplace',
  initialState: {
    boradimplaces: [],
    regAreaArr: [],
    notactivatedboardings:[],
    isLoading: false,
  },
  extraReducers: {
    [getBoardimAreaCount.pending]: (state) => {
      state.isLoading = true
    },
    [getBoardimAreaCount.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.regAreaArr = payload['data']
    },
    [getBoardimAreaCount.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllBoardimPlaces.pending]: (state) => {
      state.isLoading = true
    },
    [getAllBoardimPlaces.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.boradimplaces = payload['data']
    },
    [getAllBoardimPlaces.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllNotActivatedBoardings.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotActivatedBoardings.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notactivatedboardings = payload['data'];
    },
    [getAllNotActivatedBoardings.rejected]: (state) => {
      state.isLoading = false;
    }
  },
})

export default boradimplaceSlice.reducer
