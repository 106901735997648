import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import React, { useEffect } from 'react'
import Card from '../components/Card'
import RegistartionChart from '../components/chart/RegistartionChart'
import BoardimAreaChart from '../components/chart/BoardimAreaChart'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../api/features/userSlice'
import {
  getAllVerifiedFinder,
  getAllVerifiedOwner,
  getAllNotVerifiedFinder,
  getAllNotVerifiedOwner,
} from '../api/features/profileSlice'
import { getAllPaymentStats } from '../api/features/advertDetailPaymentSlice'
import {
  getAllNotVerifiedAdertisements,
  getAllVerifiedAdertisements,
} from '../api/features/advertisementSlice'
import advert from '../assets/advertisement.png'
import boardingplace from '../assets/boarding_place.png'
import student from '../assets/student.png'
import owner from '../assets/owner.png'
import { Skeleton } from '@material-ui/lab'
import CustomSkelton from '../components/CustomSkelton'
import { getAllNotActivatedBoardings } from '../api/features/boardimPlaceSlice'
import Cookies from "universal-cookie";



const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '17em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  cardContainer: {
    margin: '2em',
  },
  headerContainer: {
    ...theme.headerContainer,
    marginLeft: '5em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function Home() {
  const cookies = new Cookies();
  const role_id = cookies.get('role_id');
  const theme = useTheme()
  const classes = useStyles()
  // const snackRef = createRef();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useDispatch()

  const {
    notVerifyadvertisements,
    verifyadvertisements,
    isLoading,
  } = useSelector((state) => ({
    ...state.advertisement,
  }))

  const { owners } = useSelector((state) => ({
    ...state.user,
  }))

  const { paymentStats } = useSelector((state) => ({
    ...state.advertdetailpayment,
  }))
  var { notactivatedboardings } = useSelector((state) => ({
    ...state.boardimplace,
  }))

  const {
    verifiedfinder,
    verifiedowner,
    notverifiedfinder,
    notverifiedowner,
  } = useSelector((state) => ({
    ...state.profile,
  }))

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getAllNotActivatedBoardings())
    dispatch(getAllVerifiedAdertisements())
    dispatch(getAllPaymentStats())
    dispatch(getAllVerifiedOwner())
    dispatch(getAllVerifiedFinder())
    dispatch(getAllNotVerifiedFinder())
    dispatch(getAllNotVerifiedOwner())
  }, [dispatch])

  const SuperAdminstatData = [
    {
      text1: 'Verified Boarding Places',
      text2: 'Not Verified Boarding Places',
      count1: verifyadvertisements.length,
      count2: notactivatedboardings.length,
      image: boardingplace,
      bgColor: '#b2dfdb',
      route: '../boardim',
    },
    {
      text1: 'Verified Students',
      text2: 'Not Verified Students',
      count1: verifiedfinder.length,
      count2: notverifiedfinder.length,
      image: student,
      bgColor: '#ffccbc',
      route: '../boardim',
    },
    {
      text1: 'Verified Owner',
      text2: 'Not Verified Owner',
      count1: verifiedowner.length,
      count2: notverifiedowner.length,
      image: owner,
      bgColor: '#69f0ae',
      route: '#',
    },
    {
      text1: 'Paid Payments',
      text2: 'Pending Payments',
      // count1: paymentStats[0]['paid_payments'] == null ? 0 : paymentStats[0]['paid_payments'],
      // count2: paymentStats[0]['pending_payments'] == null ? 0 : paymentStats[0]['pending_payments'], 
      count1: 2,
      count2: 1,    
      image: advert,
      bgColor: '#fff176',
      route: '#',
    },
  ]

  const AdminStatData = [
    {
      text1: 'Verified Boarding Places',
      count1: verifyadvertisements.length,
      image: boardingplace,
      bgColor: '#b2dfdb',
      route: '../boardim',
    },
    {
      text1: 'Verified Students',
      count1: verifiedfinder.length,
      image: student,
      bgColor: '#ffccbc',
      route: '../boardim',
    },
    {
      text1: 'Verified Owner',
      count1: verifiedowner.length,
      image: owner,
      bgColor: '#69f0ae',
      route: '#',
    },
    {
      text1: 'Payments',
      count1: paymentStats.paid_payments != null ? paymentStats.paid_payments : 0,
      image: advert,
      bgColor: '#fff176',
      route: '#',
    },
  ]

  return (
    <>
      <Container maxWidth="xl">
        <Grid container className={classes.content}>
          {isLoading ? (
            <Grid style={{ marginLeft: '5em' }}>
              <CustomSkelton />
            </Grid>
          ) : (
            <>
              <Grid item className={classes.headerContainer}>
                <Typography variant="h4">Hi, Welcome back</Typography>
              </Grid>

              <Grid
                container
                spacing={5}
                style={{ margin: matchesSM ? '0' : '2em', marginRight: '0em' }}
              >
                {(role_id == 4 ? SuperAdminstatData : AdminStatData).map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <Card item={item} />
                  </Grid>
                ))}

                <Grid item xs={12} md={6} lg={8}>
                  <RegistartionChart />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <BoardimAreaChart />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default Home
