import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

function Recovery() {
  const [isClicked, setIsClciekd] = useState(false)
  const [isRegister, setIsRegister] = useState(false)

  return (
    <Grid
      container
      style={{ minHeight: '38vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={6} style={{ padding: '0px' }}>
        <img
          src="https://source.unsplash.com/random"
          style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
          alt="Hello , We'll back to you soon..."
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        direction="column"
        style={{ padding: 10 }}
      >
        <Grid item>
          <Card style={{ minWidth: 400 }}>
            <CardContent>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ margin: '1em' }}
              >
                <Grid item>
                  <Typography variant="h2">Boardima</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    "find a boadrim place to stay safe"
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="h5" style={{color:'red'}}>Oops...</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">Recover Email</Typography>
                <TextField
                  margin="normal"
                  variant="outlined"
                  style={{ width: '25em' }}
                />
              </Grid>

              <Grid item container alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="dashboard/home"
                >
                  Send Recover Email
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Recovery
