import { Box, Card, CardHeader } from '@material-ui/core'
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { PieChartOptions } from '../../database/TestData'
import { useDispatch, useSelector } from 'react-redux'
import { getBoardimAreaCount } from '../../api/features/boardimPlaceSlice'

function RegistartionChart() {
  const dispatch = useDispatch()
  const { regAreaArr, isLoading } = useSelector((state) => ({
    ...state.boardimplace,
  }))

  useEffect(() => {
    dispatch(getBoardimAreaCount())
  }, [dispatch])

  const areacount = regAreaArr.map((item) => item.areacount)
  const datelabel = regAreaArr.map((item) => item.gn_division)

  let PieChartData = areacount

  PieChartOptions.labels = datelabel

  return (
    <Card style={{ borderRadius: '2em', boxShadow: '1em' }}>
      <CardHeader
        title={'No of Bodims with areas'}
        subheader="(+22%) than last year"
      />
      <Box style={{ margin: '4.5em' }}>
        <ReactApexChart
          type="pie"
          series={PieChartData}
          options={PieChartOptions}
          height={280}
        />
      </Box>
    </Card>
  )
}

export default RegistartionChart
