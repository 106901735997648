import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import React, { useEffect } from 'react'
import Card from '../components/Card'
import AdvertPlanChart from '../components/chart/AdvertPlanChart'
import SubscriptionChart from '../components/chart/SubscriptionChart'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllPendingCount,
  getAllPaymentCount,
} from '../api/features/advertDetailPaymentSlice'
import {
  getAllPendingEarnings,
  getAllTotalEarnings,
} from '../api/features/customSlice'
import advert from '../assets/pending.png'
import boardingplace from '../assets/boarding_place.png'
import tot from '../assets/tot_earn.png'
import pen from '../assets/pen_earn.png'
import { Skeleton } from '@material-ui/lab'
import CustomSkelton from '../components/CustomSkelton'
import PaymentDetails from '../components/PaymentDetails'
import AdvertPlan from '../components/AdvertPlan'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '17em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  cardContainer: {
    margin: '2em',
  },
  headerContainer: {
    ...theme.headerContainer,
    marginLeft: '5em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function PaymentOverview() {
  const theme = useTheme()
  const classes = useStyles()
  // const snackRef = createRef();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useDispatch()

  const { isLoading, pendingCount, paymentCount } = useSelector((state) => ({
    ...state.advertdetailpayment,
  }))

  const { total_pending_earnings, total_earnings } = useSelector((state) => ({
    ...state.custom,
  }))

  useEffect(() => {
    dispatch(getAllPaymentCount())
    dispatch(getAllPendingCount())
    dispatch(getAllPendingEarnings())
    dispatch(getAllTotalEarnings())
  }, [dispatch])

  const statData = [
    {
      text1: 'Total Subscribers',
      count1: paymentCount.length,
      image: boardingplace,
      bgColor: '#E4FF90',
      route: '#',
    },
    {
      text1: 'Pending Subscriptions',
      count1: pendingCount,
      image: pen,
      bgColor: '#A7E4FD',
      route: '../verification/3',
    },
    {
      text1: 'Total Earnings',
      count1: `Rs : ${total_earnings === null ? 0 : total_earnings}`,
      image: tot,
      bgColor: '#E8EEC2',
      route: '#',
    },
    {
      text1: 'Pending Earnings',
      count1: `Rs : ${
        total_pending_earnings === null ? 0 : total_pending_earnings
      }`,
      image: advert,
      bgColor: '#CAF1DE',
      route: '../verification/3',
    },
  ]

  return (
    <>
      <Container maxWidth="xl">
        <Grid container className={classes.content}>
          {isLoading ? (
            <Grid style={{ marginLeft: '5em' }}>
              <CustomSkelton />
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={5}
                style={{ margin: matchesSM ? '0' : '2em', marginRight: '0em' }}
              >
                {statData.map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <Card item={item} isPayment={true} />
                  </Grid>
                ))}

                <Grid item xs={12} md={6} lg={8}>
                  <SubscriptionChart />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <AdvertPlanChart />
                </Grid>
                <Grid item container>
                  <AdvertPlan />
                </Grid>
                <Grid item>
                  <PaymentDetails />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default PaymentOverview
