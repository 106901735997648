import http from "../../http-common";

const getAll = () => {
  return http.get("/boardimplace");
};

const getRegDateCount = () => {
  return http.get("/boardimplace/areacount");
};

const getAllNotActivated = () => {
  return http.get("/boardimplace/notactivated");
};

const get = id => {
  return http.get(`/boardimplace/${id}`);
};

const create = data => {
  return http.post("/boardimplace", data);
};

const activate = (data) => {
  console.log(data)
  return http.put(`/boardimplace/activate/${data.id}`,data);
};

const update = (id, data) => {
  return http.put(`/boardimplace/${id}`, data);
};

const remove = id => {
  return http.delete(`/boardimplace/${id}`);
};


const boardimplaceService = {
  getAll,
  get,
  create,
  update,
  remove,
  getRegDateCount,
  getAllNotActivated,
  activate
};

export default boardimplaceService;