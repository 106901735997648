import { createContext, useState } from 'react'
import Cookies from "universal-cookie";

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const [auth, setAuth] = useState(null)

  const login = (auth) => {
    setAuth(auth)
    cookies.set('auth', auth);
    cookies.set('role_id', auth.user_data.role_id);
    cookies.set('user_id', auth.user_data.user_id);
    localStorage.setItem('access_token', auth.token.access_token)
  }

  const logout = () => {
    cookies.remove('role_id');
    cookies.remove('user_id');
    localStorage.removeItem('access_token')
    cookies.remove('auth');
    setAuth(null)
  }

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}


export default AuthContext
