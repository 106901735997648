import React , {useState} from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../assets/psw_clipart.png'
import img2 from '../assets/psw_clipart.png'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../api/features/userSlice'
import http from '../http-common'
import { useNavigate } from 'react-router-dom'
import useAuth from '../auth/hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: 'white',
    fontFamily: 'Poppins-Regular',
  },

  paper: {
    //  margin: theme.spacing(10,6),
    marginRight: '30px',
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',

    height: '50%',
    alignitems: 'center',
  },

  avatar: {
    marginLeft: '30px',
    margintop: '20px',
  },

  form: {
    width: '100%',
    margintop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    bgcolor: 'primary',
  },
  forget: {
    textAlign: 'right',
  },
  get: {
    textAlign: 'center',
  },
}))

function SignUp() {
  const classes = useStyles()
  const navigate = useNavigate()

  const { login } = useAuth()


  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    nic: '',
    role_id: '3',
    pwd: '',
  })

  const onChangeInput = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const registerSubmit = async (e) => {
    e.preventDefault()
    try {
      localStorage.setItem('firstLogin', true)

      const response = await http.post('/signup', user)
      if (response.data.data != null) {
        login(response.data.data)
        navigate('/dashboard/home')
        
      }
    } catch (err) {
      alert(err.response.data.msg)
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        component="main"
        alignItems="center"
        justifyContent="center"
      >
        <CssBaseline />
        <Grid item xs={12} sm={4} md={8}>
          <img
            src={logo}
            width="80%"
            alt=""
            margintop="30px"
            height="80%"
            elevation={0}
            backgroundrepeat="no-repeat"
            backgroundsize="cover"
            backgroundposition="center"
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={0} square>
          <img src={img2} alt="" className={classes.avatar} height={50} />
          <div className={classes.paper}>
            <Typography component="h2" variant="h5" color="primary">
              SignUp
            </Typography>
            <form className={classes.form} noValidate onSubmit={registerSubmit}>
              <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="First Name"
                label="First Name"
                name="first_name"
                autoComplete="First Name"
                autoFocus
                size="small"
              />
                            <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="Last Name"
                label="Last Name"
                name="last_name"
                autoComplete="Last Name"
                autoFocus
                size="small"
              />
              <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                size="small"
              />
              <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="tp"
                label="Telephone No"
                name="tp"
                autoComplete="tp"
                autoFocus
                size="small"
              />
              <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="nic"
                label="NIC No"
                name="nic"
                autoComplete="nic"
                autoFocus
                size="small"
              />
              <TextField
                onChange={onChangeInput}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="pwd"
                label="Password"
                type="password"
                id="pwd"
                autoComplete="password"
                size="small"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                textalign="left"
              >
                SignUp
              </Button>

              <Grid container className={classes.get}>
                <Grid item xs>
                Already have an account ?
                  <Button variant="text" style={{ color: '#0b72B9' , textTransform:'lowercase'}} component={Link} to="/">Login here</Button>
              </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignUp
