import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { FiberManualRecord, Send } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ImageAvatar from './ImageAvatar'
import RoomIcon from '@material-ui/icons/Room'
import ImageCarousel from './ImageCarousel'
import ErrorImg from '../assets/error.png'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { useDispatch } from 'react-redux'
import { sendToDevice } from '../api/features/cloudMessagingSlice'
import { useState } from 'react'
import CustomSnackBar from './CustomSnackBar'

function TableOwnerBody({ rowData }) {
  //   const location = {
  //     lat: rowData.boardimplace.BoardimPlace.lat,
  //     lng: rowData.boardimplace.BoardimPlace.lng,
  //   }
  const [flag, setFlag] = useState(false)
  const dispatch = useDispatch()

  const sendMessage = async (e, id) => {
    e.preventDefault()
    const payload = {
      id: '',
      title: 'Owner Alert',
      msg: '',
    }
    payload.id = id
    payload.msg = document.getElementById('msg').value
    await dispatch(sendToDevice(payload))
    document.getElementById('msg').value = ''
    setFlag(true)
  }

  return (
    <>
      {flag ? (
        <CustomSnackBar msg="Message sent Successfully" isOpen={flag} />
      ) : null}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent={'space-evenly'}
        style={{ margin: '1em 5em' }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent={'space-evenly'}
        >
          <Grid item>
            {' '}
            <ImageAvatar
              img={
                rowData.user_img_url == null ? ErrorImg : rowData.user_img_url
              }
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
              style={{ margin: '1em' }}
            >
              <>
                <Grid item>
                  <Typography>
                    {' '}
                    <b>Owner : </b>
                    {rowData.first_name} {rowData.last_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Address : </b> {rowData.address}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Contact Number : </b> {rowData.tp}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {' '}
                    <b>Email : </b>
                    {rowData.email}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>NIC : </b>
                    {rowData.nic}
                  </Typography>
                </Grid>
                <Grid item container direction="row" alignContent="center">
                  <Grid item style={{ color: 'green', marginRight: '0.5em' }}>
                    <VerifiedUserIcon />
                  </Grid>
                  <Grid item>
                    <Typography
                      Typography="h5"
                      style={{ color: 'green', fontWeight: '2em' }}
                    >
                      <b>{rowData.is_verify ? 'Verified' : 'Not Verified'} </b>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          style={{ marginLeft: '1em' }}
        >
          {/* <form onSubmit={sendMessage}> */}
          <Grid item xs={9}>
            <TextField
              id="msg"
              label="Custom Message"
              placeholder="send a message..."
              fullWidth
              margin="normal"
              variant="outlined"
              // onChange={onChangeInput}
              name="msg"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<Send />}
              style={{ margin: '1em 1em' }}
              color="primary"
              // onClick={(e) => sendMessage(e, rowData.id)}
            >
              Send
            </Button>
          </Grid>
          {/* </form> */}
        </Grid>
      </Grid>
    </>
  )
}

export default TableOwnerBody
