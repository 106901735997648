export const studentHeader = [
    {
      title: 'Name',
      field: 'Finder.first_name',
    },   
    { title: 'Title', field: 'BoardimPlace.description' ,defaultGroupOrder: 0},
    { title: 'Boardim Contact Number', field: 'Finder.tp', type: 'numeric' },
    { title: 'Registered Date', field: 'date' },
    { title: 'Address', field: 'BoardimPlace.address' },
  ];

  export const vericationheader = [
    {
      title: 'Name',
      field: 'first_name',
    },   
    { title: 'Email', field: 'email' },
    { title: 'NIC', field: 'nic', type: 'numeric' },
    { title: 'Contact Number', field: 'tp', type: 'numeric' },
    { title: 'Address', field: 'address' },
  ];

  export const paymentDetailHeader = [
    {
      title: 'Owner',
      field: 'Advertisement.Owner.first_name',
    },   
    { title: 'Boardim Place', field: 'Advertisement.BoardimPlace.description' },
    { title: 'Contact Number', field: 'Advertisement.Owner.tp', type: 'numeric' },
    { title: 'Payed Date', field: 'date' },
    { title: 'Subcription Plan', field: 'AdvertPlan.name' },
    { title: 'Expire Date', field: 'expire_date' },
  ];

  export const advertPlanHeader = [
    {
      title: 'Plan',
      field: 'name',
    },   
    { title: 'Cost', field: 'cost', type: 'numeric' },
    { title: 'Duration (Months)', field: 'duration' },
  ];

  
  export const boardimPlaceHeader = [
    {
      title: 'Owner',
      field: 'boardimplace.Owner.first_name',
      defaultGroupOrder: 0
    },   
    
    { title: 'Title', field: 'boardimplace.BoardimPlace.description' },
    { title: 'Address', field: 'boardimplace.BoardimPlace.address' },
    { title: 'Contact Number', field: 'boardimplace.Owner.tp', type: 'numeric' },
    { title: 'Preffered Gender', field: 'boardimplace.BoardimPlace.preffered_gender' },
    { title: 'City', field: 'boardimplace.BoardimPlace.city' },
  ];

  export const boardimPlaceverifyHeader = [
    {
      title: 'Owner',
      field: 'boardimplace.Owner.first_name',
    },   
    { title: 'Address', field: 'boardimplace.BoardimPlace.address' },
    { title: 'Contact Number', field: 'boardimplace.Owner.tp', type: 'numeric' },
    { title: 'Preffered Gender', field: 'boardimplace.BoardimPlace.preffered_gender' },
    { title: 'City', field: 'boardimplace.BoardimPlace.city' },
  ];

  export const ownerHeader = [
    {
      title: 'Owner',
      field: 'boardimplace.Owner.first_name',
    },   
    { title: 'Address', field: 'boardimplace.BoardimPlace.address' },
    { title: 'Contact Number', field: 'boardimplace.Owner.tp', type: 'numeric' },
    { title: 'Preffered Gender', field: 'boardimplace.BoardimPlace.preffered_gender' },
    { title: 'City', field: 'boardimplace.BoardimPlace.city' },
  ];

  export const ownerVerificationHeader = [
    {
      title: 'Owner',
      field: 'Owner.first_name',
    },   
    { title: 'Address', field: 'BoardimPlace.address' },
    { title: 'Contact Number', field: 'Owner.tp', type: 'numeric' },
    { title: 'Preffered Gender', field: 'BoardimPlace.preffered_gender' },
    { title: 'City', field: 'BoardimPlace.city' },
  ];

  export const messageHeader = [
    {
      title: 'Audience',
      field: 'Audience.name',
      defaultGroupOrder: 0
    },   
    { title: 'Subject ', field: 'subject' },
    { title: 'date ', field: 'createdAt' }
  ];

  export const paymentHeader = [
    {
      title: 'Owner',
      field: 'Advertisement.Owner.first_name',
      defaultGroupOrder: 0
    },   
    { title: 'Title', field: 'Advertisement.BoardimPlace.title' },
    { title: 'Address', field: 'Advertisement.BoardimPlace.address' },
    { title: 'Contact Number', field: 'Advertisement.Owner.tp', type: 'numeric' },
    { title: 'Advert Plan', field: 'AdvertPlan.name' },
    { title: 'Publish Date', field: 'Advertisement.publish_date' },
    { title: 'Expire Date', field: 'expire_date' },
  ];