import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import TokenService from "../services/tokenService";

export const getAccessToken = createAsyncThunk(
  "token/get",
  async ( id ) => {
    const res = await TokenService.get(id);
    return res.data;
  }
);

const tokenSlice = createSlice({
  name: 'token', 
  initialState: {
    token: '',
    isLoading: false,
    access_token : ''
  },
  extraReducers: {
    [getAccessToken.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAccessToken.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.access_token = payload['data'];
    },
    [getAccessToken.rejected]: (state) => {
      state.isLoading = false;
    },
  },
})


export default tokenSlice.reducer
