import http from "../../http-common";

const getAll = () => {
  return http.get("/boardimimage");
};

const get = id => {
  return http.get(`/boardimimage/${id}`);
};

const create = data => {
  return http.post("/boardimimage", data);
};

const update = (id, data) => {
  return http.put(`/boardimimage/${id}`, data);
};

const remove = id => {
  return http.delete(`/boardimimage/${id}`);
};


const BoardimImageService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default BoardimImageService;