import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import Table from './Table'
import { paymentDetailHeader } from '../database/TableHeaders'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAdvertDetailPayment } from '../api/features/advertDetailPaymentSlice'
import { getStudentCount } from '../api/features/registrationSlice'
import TablePaymentDetailsBody from './TablePaymentDetailsBody'
import { Skeleton } from '@material-ui/lab'
import CustomSkelton from './CustomSkelton'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginTop: '2em',
    marginLeft: '-2em',
    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function PaymentDetails() {
  const dispatch = useDispatch()
  const { adevertdetailpayments , isLoading } = useSelector((state) => ({
    ...state.advertdetailpayment,
  }))

  const { studentcount  } = useSelector((state) => ({
    ...state.registration,
  }))

  useEffect(() => {
    // dispatch(getStudentCount())
    // dispatch(getAllAdvertDetailPayment())
  }, [dispatch])

  const classes = useStyles()

  const tablePaymentDetailsBody = (data) => <TablePaymentDetailsBody rowData={data} />
  const paymentData = adevertdetailpayments.map((o) => ({ ...o }))


  return (
    <>
      <Container maxWidth="xl">
        <Grid container className={classes.content}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {isLoading ? (
              <>
                <Grid item>
                  <CustomSkelton/>
                </Grid>
              </>
            ) : (
              <>
                <Grid item style={{ marginBottom: '2em' }}>
                  <Typography variant="h4">Payment Details </Typography>
                </Grid>
                <Grid item>
                  <Table
                    data={paymentData}
                    columns={paymentDetailHeader}
                    childs={tablePaymentDetailsBody}
                    flag= {false}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default PaymentDetails
