import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textDecoration: 'none',
    width: '20em',
    height: '20em',
    [theme.breakpoints.down('md')]: {
      width: '27em',
      height: '18em',
    },
    borderRadius: '2em',
    boxShadow: 'none',
  },
  details: {
    display: 'flex',
    // flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 90,
    height: 90,
    marginTop: '2em',
  },
}))

export default function MediaControlCard({ item, isPayment = false }) {
  const classes = useStyles()
  //   const theme = useTheme();
  const cookies = new Cookies()
  const role_id = cookies.get('role_id')

  return (
    <Card
      className={classes.root}
      style={{
        backgroundColor: 'white',
        border: '5px solid',
        borderColor: item.bgColor,
      }}
    >
      <CardActionArea to={item.route} component={Link}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction='column'
        >
          <Grid item>
            <CardMedia
              className={classes.cover}
              image={item.image}
              title="Live from space album cover"
            />
          </Grid>
          <CardContent className={classes.content}>
            <Grid
              item
              container
              direction={isPayment ? 'column' : 'row'}
              className={classes.details}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'center',
                    margin: role_id == 4 ? '0em' : '2em',
                    // marginLeft: role_id == 4 ? '2em' : '0em' ,
                  }}
                >
                  {item.text1}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1" color="textSecondary">
                  <b>{item.count1}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              className={classes.details}
              alignItems="center"
              justifyContent="space-between"
            >
              {role_id == 4 ? (
                <>
                  <Grid>
                    <Typography
                      variant="body2"
                      style={{ textAlign: 'center', marginRight: '3em' }}
                    >
                      {item.text2}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="subtitle1" color="textSecondary">
                      <b>{item.count2}</b>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </CardContent>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
