import http from '../../http-common'
import Cookies from 'universal-cookie'


const getAll = () => {
  return http.get('/profile')
}

const getAllVerifiedFinder = () => {
  return http.get('/profile/verifiedfinder')
}

const getAllVerifiedOwner = () => {
  return http.get('/profile/verifiedowner')
}

const getAllNotVerifiedFinder = () => {
  return http.get('/profile/notverifiedfinder')
}

const getAllNotVerifiedOwner = () => {
  return http.get('/profile/notverifiedowner')
}

const get = (id) => {
 const token  = localStorage.getItem('access_token');
  return http.get(`/profile/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  })
}

const create = (data) => {
  return http.post('/profile', data)
}

const update = (id, data) => {
  return http.put(`/profile/${id}`, data)
}

const updateUserVerification = (id) => {
    return http.put(`/profile/isverify/${id}`)
  }

const remove = (id) => {
  return http.delete(`/profile/${id}`)
}

const ProfileService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateUserVerification,
  getAllNotVerifiedFinder,
  getAllNotVerifiedOwner,
  getAllVerifiedFinder,
  getAllVerifiedOwner
}

export default ProfileService
