import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import Table from './Table'
import { advertPlanHeader } from '../database/TableHeaders'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAdvertPlan } from '../api/features/advertPlanSlice'
import { getStudentCount } from '../api/features/registrationSlice'
import { Skeleton } from '@material-ui/lab'
import CustomSkelton from './CustomSkelton'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginTop: '2em',
    marginLeft: '-2em',
    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function AdvertPlan() {
  const dispatch = useDispatch()
  const [flag , setFlag] = useState(false);
  const { advertplans , isLoading } = useSelector((state) => ({
    ...state.advertplan,
  }))

  
  useEffect(() => {
    dispatch(getAllAdvertPlan())
  }, [ dispatch , flag])

  const changeState  = ()=>{
    console.log("CLICKED")
    setFlag(true)
  }

  const classes = useStyles()

  const planData = advertplans.map((o) => ({ ...o }))


  return (
    <>
      <Container maxWidth="xl">
        <Grid container className={classes.content}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {isLoading ? (
              <>
                <Grid item>
                  <CustomSkelton/>
                </Grid>
              </>
            ) : (
              <>
                <Grid item style={{ marginBottom: '2em' }}>
                  <Typography variant="h4">Advert Plan Details </Typography>
                </Grid>
                <Grid item>
                  <Table
                    data={planData}
                    columns={advertPlanHeader}
                    flag= {false}
                    changeState = {changeState}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default AdvertPlan
