import { Button, Grid, Typography } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ImageAvatar from './ImageAvatar'
import RoomIcon from '@material-ui/icons/Room'
import ImageCarousel from './ImageCarousel'
import ErrorImg from '../assets/error.png'
import { activateBoardimPlace } from '../api/features/boardimPlaceSlice'
import { useDispatch, useSelector } from 'react-redux'

function TableBoardimBody({ rowData, activate = false }) {

  const dispatch = useDispatch();

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent={'space-evenly'}
        style={{ margin: '1em 5em' }}
      >
        {rowData.boarding_images.length === 0 ? (
          <></>
        ) : (
          <>
            <Grid item>
              <Typography variant="h5" style={{ marginBottom: '1em' }}>
                Boarding Images
              </Typography>
            </Grid>
            <Grid item>
              <ImageCarousel boardimimages={rowData.boarding_images} />
            </Grid>
          </>
        )}

        <Grid item>
          <Typography variant="h5" style={{ marginTop: '1em' }}>
            Boarding Details
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent={'space-evenly'}
        >
          <Grid item>
            {' '}
            <ImageAvatar
              img={
                rowData.boardimplace.Owner.user_img_url == null
                  ? ErrorImg
                  : rowData.boardimplace.Owner.user_img_url
              }
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems={'flex-start'}
              style={{ margin: '1em' }}
            >
              <>
                <Grid item>
                  <Typography>
                    {' '}
                    <b>Owner : </b>
                    {rowData.boardimplace.Owner.first_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Contact Number : </b> {rowData.boardimplace.Owner.tp}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {' '}
                    <b>Boardim Type : </b>
                    {rowData.boardimplace.BoardimPlace.preffered_gender}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>No of Boarders : </b>
                    {rowData.boardimplace.BoardimPlace.no_of_people}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {' '}
                    <b>Features :</b>
                  </Typography>
                  {/* {rowData.features.map((item, index) => (
                <Grid key={index} item container style={{ marginLeft: '2em' }}>
                  <Grid item>
                    <FiberManualRecord
                      style={{
                        height: '10px',
                        width: '10px',
                        marginRight: '0.5em',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{item}</Typography>
                  </Grid>
                </Grid>
              ))} */}
                </Grid>
                <Grid item>
                  {activate ? (
                    <Button
                      variant="contained"
                      endIcon={<RoomIcon />}
                      style={{ margin: '1em 0em' }}
                      color="primary"
                      onClick={()=> dispatch(activateBoardimPlace(rowData.boardimplace.BoardimPlace.id))}
                    >
                      Verify here
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: '/simplemap',
                        state: {
                          location: rowData.location,
                          owner: rowData.owner,
                        },
                      }}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<RoomIcon />}
                        style={{ margin: '1em 0em' }}
                        color="primary"
                      >
                        View on Map
                      </Button>
                    </Link>
                  )}
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TableBoardimBody
