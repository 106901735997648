import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  Typography,
  Container,
  makeStyles,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { tableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAdvertPlan,
  deleteAdvertPlan,
  createAdvertPlan,
} from '../api/features/advertPlanSlice'
import { vericationheader } from '../database/TableHeaders'
import {
  getAllNotVerifiedOwner,
  updateProfileStatus,
} from '../api/features/profileSlice'
import ImageZoom from './ImageZoom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorImg from '../assets/error.png'
import CustomSkelton from './CustomSkelton'
import CustomSnackBar from './CustomSnackBar'
import { Send } from '@material-ui/icons'
import { sendToDevice } from '../api/features/cloudMessagingSlice'
import Test from './test'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginTop: '1em',
    marginLeft: '6em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function OwnerVerificationTable() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [flag, setFlag] = useState(false)
  const [flag2, setFlag2] = useState(false)
  const [clicked, setCliked] = useState(false)
  const dispatch = useDispatch()

  const [cldmsg, setCldMsg] = useState({
    id: '11',
    title: 'Owner Verification Alert',
    msg: '',
  })

  var { notverifiedowner, isLoading } = useSelector((state) => ({
    ...state.profile,
  }))

  const sendMessage = async (e, id) => {
    e.preventDefault()
    const payload = {
      id: '',
      title: 'Owner Verification Alert',
      msg: '',
    }
    payload.id = id
    payload.msg = document.getElementById('msg').value
    await dispatch(sendToDevice(payload))
    document.getElementById('msg').value = ''
    setFlag2(true)
  }

  useEffect(() => {
    dispatch(getAllNotVerifiedOwner())
    setFlag(false)
  }, [])

  if (!Array.isArray(notverifiedowner)) {
    notverifiedowner = [notverifiedowner]
  }
  const studentData = notverifiedowner.map((o) => ({ ...o }))

  return (
    <Container maxWidth="xl">
      {flag ? (
        <CustomSnackBar msg="verification Success" isOpen={flag} />
      ) : null}
      {flag2 ? (
        <CustomSnackBar msg="Message sent Successfully" isOpen={flag} />
      ) : null}
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Grid item>
                <CustomSkelton />
              </Grid>
            </>
          ) : (
            <>
              <Grid item style={{ marginBottom: '2em' }}>
                <Typography variant="h4">Owner Verification </Typography>
              </Grid>
              <Grid item>
                <MaterialTable
                  tableIcons={tableIcons}
                  style={{ width: matchesSM ? '25em' : '95em' }}
                  title={''}
                  columns={vericationheader.map((c) => ({
                    ...c,
                    tableData: undefined,
                  }))}
                  data={studentData}
                  detailPanel={(rowData) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={'center'}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-evenly"
                          style={{ margin: '1em' }}
                        >
                          <Grid
                            item
                            container
                            justifyContent={'space-evenly'}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item>
                              <ImageZoom
                                url={
                                  rowData.nic_front_img_url == null
                                    ? ErrorImg
                                    : rowData.nic_front_img_url
                                }
                                caption={'Front View'}
                              />
                            </Grid>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                            />

                            <Grid item>
                              <Grid
                                item
                                style={{
                                  marginTop: '2em',
                                  marginBottom: '2em',
                                }}
                                direction="row"
                              >
                                <Button
                                  variant="contained"
                                  disabled={
                                    rowData.nic_front_img_url == null ||
                                    rowData.nic_back_img_url == null
                                  }
                                  endIcon={<CheckCircleIcon />}
                                  color="primary"
                                  onClick={() => {
                                    dispatch(updateProfileStatus(rowData.id))
                                    dispatch(getAllNotVerifiedOwner())
                                    setFlag(true)
                                  }}
                                >
                                  Verify here
                                </Button>
                              </Grid>
                            </Grid>

                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                            />
                            <Grid item>
                              <ImageZoom
                                url={
                                  rowData.nic_back_img_url == null
                                    ? ErrorImg
                                    : rowData.nic_back_img_url
                                }
                                caption={'Back View'}
                              />
                            </Grid>

                            <Grid
                              item
                              container
                              direction="row"
                              spacing={3}
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ marginLeft: '1em' }}
                            >
                              {/* <form onSubmit={sendMessage}> */}
                              <Grid item xs={9}>
                                <TextField
                                  id="msg"
                                  label="Custom Message"
                                  placeholder="send a message..."
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  name="msg"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Button
                                  // disabled={document.getElementById('msg').value != ''}
                                  type="submit"
                                  variant="contained"
                                  endIcon={<Send />}
                                  style={{ margin: '1em 1em' }}
                                  color="primary"
                                  onClick={(e) => sendMessage(e, rowData.id)}
                                >
                                  Send
                                </Button>
                              </Grid>
                              {/* </form> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }}
                  options={{
                    headerStyle: {
                      backgroundColor: '#01579b',
                      color: 'white',
                    },
                    paging: false,
                    selection: true,
                    grouping: true,
                    defaultExpanded: true,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default OwnerVerificationTable
