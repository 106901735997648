import { Skeleton } from '@material-ui/lab'
import React from 'react'

function CustomSkelton() {
  return (
    <>
      <Skeleton variant="text" />
      <Skeleton variant="text" width="90%" />
      <Skeleton width="80%" />
      <Skeleton width="60%" />
      <Skeleton variant="rect" width={'80em'} height={'60em'} />
    </>
  )
}

export default CustomSkelton
