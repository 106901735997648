import { Box, Card, CardHeader, useMediaQuery } from '@material-ui/core'
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { chartOptions } from '../../database/TestData'
import { useTheme } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getRegDateCount } from '../../api/features/registrationSlice'

function RegistartionChart() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { regDateArr, isLoading } = useSelector((state) => ({
    ...state.registration,
  }))

  useEffect(() => {
    dispatch(getRegDateCount())
  }, [dispatch])

  const regcount = regDateArr.map((item) => item.regcount)
  const datelabel = regDateArr.map((item) => item.date)

  const chartData = [
    {
      name: 'Science',
      type: 'area',
      data: regcount,
    },
  ]

  chartOptions.labels = datelabel

  return (

    <Card style={{ borderRadius: '2em', boxShadow: '1em' }}>
      <CardHeader
        title={'Registrations per month'}
        subheader="(+43%) than last year"
      />
      <Box>
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          height={matchesSM ? 200 : 364}
        />
      </Box>
    </Card>
  )
}

export default RegistartionChart
