import { Button, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import ImageAvatar from './ImageAvatar'
import ErrorImg from '../assets/error.png'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { Send } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { sendToDevice } from '../api/features/cloudMessagingSlice'
import CustomSnackBar from './CustomSnackBar'
import { useState } from 'react'

function TablBody({ rowData }) {
  const dispatch = useDispatch()
  const [flag, setFlag] = useState(false)

  const sendMessage = async (e, id) => {
    e.preventDefault()
    const payload = {
      id: '',
      title: 'Student Alert',
      msg: '',
    }
    payload.id = id
    payload.msg = document.getElementById('msg').value
    await dispatch(sendToDevice(payload))
    document.getElementById('msg').value = ''
    setFlag(true)
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={'space-evenly'}
    >
      {flag ? (
        <CustomSnackBar msg="Message sent Successfully" isOpen={flag} />
      ) : null}

      <Grid item>
        <ImageAvatar
          img={rowData.user_img_url == null ? ErrorImg : rowData.user_img_url}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems={'flex-start'}
          style={{ margin: '1em' }}
        >
          <>
            <Grid item>
              <Typography>
                <b>Name : </b>
                {rowData.first_name + ' ' + rowData.last_name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Contact Number : </b>
                {rowData.tp}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Email : </b>
                {rowData.email}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Special Notices : </b>
                {rowData.note == null ? 'Not any special notes' : rowData.note}
              </Typography>
            </Grid>
            <Grid item container direction="row" alignContent="center">
              <Grid item style={{ color: 'green', marginRight: '0.5em' }}>
                <VerifiedUserIcon />
              </Grid>
              <Grid item>
                <Typography
                  Typography="h5"
                  style={{ color: 'green', fontWeight: '2em' }}
                >
                  <b>{rowData.is_verify ? 'Verified' : 'Not Verified'} </b>
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginLeft: '1em' }}
      >
        {/* <form onSubmit={sendMessage}> */}
        <Grid item xs={9}>
          <TextField
            id="msg"
            label="Custom Message"
            placeholder="send a message..."
            fullWidth
            margin="normal"
            variant="outlined"
            // onChange={onChangeInput}
            name="msg"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<Send />}
            style={{ margin: '1em 1em' }}
            color="primary"
            onClick={(e) => sendMessage(e, rowData.id)}
          >
            Send
          </Button>
        </Grid>
        {/* </form> */}
      </Grid>
    </Grid>
  )
}

export default TablBody
