import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Avatar,
  Badge,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import logo from '../assets/logo.png'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import InfoIcon from '@material-ui/icons/Info'
import AssignmentIcon from '@material-ui/icons/Assignment'
import MapIcon from '@material-ui/icons/Map'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileById } from '../api/features/profileSlice'
import { logoutUser } from '../api/features/userSlice'
import useAuth from '../auth/hooks/useAuth'
import Cookies from 'universal-cookie'

const drawerWidth = 270

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    // width: "100%",
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
    height: '5em',
  },
  menuButton: {
    marginLeft: 'auto',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: '5em',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    marginLeft: 'auto',
    marginRight: '2em',
  },
  logoContainer: {
    padding: 0,
    margin: '1em',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logo: {
    height: '4em',
    margin: '1em 0em',
    [theme.breakpoints.down('md')]: {
      height: '4em',
    },
  },
  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      marginRight: '1em',
    },
  },
  active: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#00adb5',
    },
    '&:focus': {
      backgroundColor: '#0b72B9',
    },
  },
}))

function MainHeader(props) {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined

  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))

  const [mobileOpen, setMobileOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const dispatch = useDispatch()
  const cookies = new Cookies()
  const role_id = cookies.get('role_id')
  const user_id = cookies.get('user_id')
  const auth = cookies.get('auth')
  const location = useLocation()

  const { userProfile, isLoading } = useSelector((state) => ({
    ...state.profile,
  }))

  const { logoutStatus } = useSelector((state) => ({
    ...state.user,
  }))

  useEffect(() => {
    if (cookies.get('auth') == null) {
      <Navigate to="/" state={{ from: location }} replace />
    }
    dispatch(getProfileById(user_id))
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLogout = async () => {
    try {
      dispatch(logoutUser())
      if (logoutStatus.msg == 'USER LOGOUT') {
        logout()
        navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const routes =
    role_id == 4
      ? [
          { name: 'Home', link: 'home', activeIndex: 0, icon: <HomeIcon /> },
          {
            name: 'Student Details',
            link: 'student',
            activeIndex: 1,
            icon: <InfoIcon />,
          },
          {
            name: 'Boarding Places',
            link: 'boardim',
            activeIndex: 2,
            icon: <ContactPhoneIcon />,
          },
          {
            name: 'Owner Details',
            link: 'owner',
            activeIndex: 3,
            icon: <AccountCircleIcon />,
          },
          {
            name: 'Registrations',
            link: 'registration',
            activeIndex: 4,
            icon: <ChromeReaderModeIcon />,
          },
          {
            name: 'View on Map',
            link: 'map',
            activeIndex: 6,
            icon: <MapIcon />,
          },
          {
            name: 'Verificataion Center',
            link: 'verification/1',
            activeIndex: 5,
            icon: <VerifiedUserIcon />,
          },
          {
            name: 'Payment Overview',
            link: 'payment',
            activeIndex: 7,
            icon: <MonetizationOnIcon />,
          },
        ]
      : [
          { name: 'Home', link: 'home', activeIndex: 0, icon: <HomeIcon /> },
          {
            name: 'Student Details',
            link: 'student',
            activeIndex: 1,
            icon: <InfoIcon />,
          },
          {
            name: 'Boarding Places',
            link: 'boardim',
            activeIndex: 2,
            icon: <ContactPhoneIcon />,
          },
          {
            name: 'Owner Details',
            link: 'owner',
            activeIndex: 3,
            icon: <AccountCircleIcon />,
          },
          {
            name: 'Registrations',
            link: 'registration',
            activeIndex: 4,
            icon: <ChromeReaderModeIcon />,
          },
          {
            name: 'View on Map',
            link: 'map',
            activeIndex: 6,
            icon: <MapIcon />,
          },
        ]

  const drawer = (
    <div>
      <List>
        {routes.map((route, index) => (
          <ListItem button key={index} component={Link} to={route.link}>
            <ListItemIcon style={{ color: '#0b72B9' }}>
              {route.icon}
            </ListItemIcon>
            <ListItemText primary={route.name} variant="body1" />
          </ListItem>
        ))}
      </List>
    </div>
  )

  const desktopDrawer = (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      open
    >
      {drawer}
    </Drawer>
  )

  const mobileDrawer = (
    <Drawer
      container={container}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      style={{ zIndex: 1302 }}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {drawer}
    </Drawer>
  )

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters>
            <Button className={classes.logoContainer} disableRipple>
              <img src={logo} alt="company logo" className={classes.logo} />
              <Typography
                variant="h4"
                style={{ color: 'white', marginLeft: '0.5em' }}
              >
                Boardima
              </Typography>
            </Button>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.mobileContainer} />
            </IconButton>
            <Grid className={classes.sectionDesktop}>
              <IconButton
                aria-label="show 4 new mails"
                color="inherit"
                component={Link}
                to={'message'}
              >
                <Badge badgeContent={6} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <Avatar
                  alt="Remy Sharp"
                  src={
                    userProfile.user_img_url == null
                      ? 'https://mui.com/static/images/avatar/1.jpg'
                      : userProfile.user_img_url
                  }
                  onClick={handleClick}
                />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ top: '4em' }}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to="profile"
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>

        <nav
          className={classes.drawer}
          aria-label="mailbox folders"
          style={{ alignItems: 'right' }}
        >
          {matchesMD ? mobileDrawer : desktopDrawer}
        </nav>
      </div>
    </>
  )
}

MainHeader.propTypes = {
  window: PropTypes.func,
}

export default MainHeader
