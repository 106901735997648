import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CustomService from '../services/customService'

export const getAllTotalEarnings = createAsyncThunk(
  'custom/getAllTotalEarnings',
  async () => {
    const res = await CustomService.getAllTotalEarnings()
    return res.data
  },
)

export const getAllPendingEarnings = createAsyncThunk(
  'custom/getAllPendingEarnings',
  async () => {
    const res = await CustomService.getAllPendingEarnings()
    return res.data
  },
)

export const getAllSubViceAmount = createAsyncThunk(
  'custom/getAllSubViceAmount',
  async () => {
    const res = await CustomService.getAllSubViceAmount()
    return res.data
  },
)

const customSlice = createSlice({
  name: 'custom',
  initialState: {
    total_pending_earnings:0,
    subviceamount:[],
    total_earnings:0,
    isLoading: false,
  },
  extraReducers: {
    [getAllTotalEarnings.pending]: (state) => {
      state.isLoading = true
    },
    [getAllTotalEarnings.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.total_earnings = payload['data'][0].total_earnings
    },
    [getAllTotalEarnings.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllPendingEarnings.pending]: (state) => {
      state.isLoading = true
    },
    [getAllPendingEarnings.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.total_pending_earnings = payload['data'][0].total_pending_earnings
    },
    [getAllPendingEarnings.rejected]: (state) => {
      state.isLoading = false
    },
    [getAllSubViceAmount.pending]: (state) => {
      state.isLoading = true
    },
    [getAllSubViceAmount.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.subviceamount = payload['data']
    },
    [getAllSubViceAmount.rejected]: (state) => {
      state.isLoading = false
    },
  },
})

// export const { getStudents, getOwners } = customSlice.actions

export default customSlice.reducer
