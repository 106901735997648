import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CloudMessageService from "../services/cloudMessagingService";

export const sendToDevice= createAsyncThunk(
  "fcm/sendtodevice",
  async ( data ) => {
    const res = await CloudMessageService.sendtodevice(data);
  }
);

export const bulkSendToDevice= createAsyncThunk(
  "fcm/bulksendtodevice",
  async ( data ) => {
    console.log(data)
    const res = await CloudMessageService.bulksendtodevice(data);
  }
);


const cloudMessageSlice = createSlice({
  name: 'fcm', 
  initialState: {
    message: [],
    isLoading: false,
  },
  extraReducers: {
  },
})


export default cloudMessageSlice.reducer
