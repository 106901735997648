import Home from './screens/Home'
import Login from './screens/Login'
import Student from './screens/Student'
import Boardim from './screens/Boardim'
import Verification from './screens/Verification'
import ViewOnMap from './screens/ViewOnMap'
import Layout from './screens/Layout'
import Message from './screens/Message'
import SimpleMap from './screens/SimpleMap'
import PaymentOverview from './screens/PaymentOverview'
import SignUp from './screens/SignUp'
import Recovery from './screens/Recovery'
import Profile from './screens/Profile'
import Admin from './screens/admin'
import Owner from './screens/Owner'
import Registration from './screens/Registration'

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import RequireAuth from './auth/RequiredAuth'
import { AuthProvider } from './auth/context/AuthProvider'
import Unauthorized from './screens/Unauthorized'
import Missing from './screens/Missing'
import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useState } from 'react'

const ROLES = {
  Admin: 3,
  SuperAdmin: 4,
}

function Routings() {
  const cookies = new Cookies()
  const location = useLocation()


  useEffect(() => {
    if (cookies.get('auth') == null) {
      <Navigate to="/" state={{ from: location }} replace />
    }
  }, [])

  return (
    <AuthProvider>
      <Routes>
        <Route path="/">
          <Route path="/" element={<Login />} exact />
          <Route path="signup" element={<SignUp />} />
          <Route path="unauthorized" element={<Login />} />

          <Route path="/dashboard">
            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.SuperAdmin, ROLES.Admin]} />
              }
            >
              <Route path="student" element={<Student />} />
              <Route path="home" element={<Home />} />
              <Route path="owner" element={<Owner />} />
              <Route path="boardim" element={<Boardim />} />
              <Route path="map" element={<ViewOnMap />} />
              <Route path="message" element={<Message />} />
              <Route path="profile" element={<Profile />} />
              <Route path="registration" element={<Registration />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
              <Route path="verification/:id" element={<Verification />} />
              <Route path="payment" element={<PaymentOverview />} />
            </Route>
          </Route>
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default Routings
