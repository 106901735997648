import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import { studentHeader } from '../database/TableHeaders'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRegistrations } from '../api/features/registrationSlice'
import TableStudent from '../components/TableStudentBody'
import { Skeleton } from '@material-ui/lab'
import CustomSkelton from '../components/CustomSkelton'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function Registration() {
  const dispatch = useDispatch()
  const { registrations , isLoading } = useSelector((state) => ({
    ...state.registration,
  }))

  useEffect(() => {
    dispatch(getAllRegistrations())
  }, [dispatch])

  const classes = useStyles()

  const tableStudentBody = (data) => <TableStudent rowData={data} />
  const studentData = registrations.map((o) => ({ ...o }))
  return (
    <>
      <Container maxWidth="xl">
        <Grid container className={classes.content}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? (
              <>
                <Grid item>
                  <CustomSkelton/>
                </Grid>
              </>
            ) : (
              <>
                <Grid item style={{ marginBottom: '2em' }}>
                  <Typography variant="h4">Registration Details </Typography>
                </Grid>
                <Grid item>
                  <Table
                    data={studentData}
                    columns={studentHeader}
                    childs={tableStudentBody}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Registration