import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter , HashRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

let persistor = persistStore(store);


ReactDOM.render(
  <>
    <Provider store={store}>
      <HashRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </HashRouter>
    </Provider>
  </>,
  document.getElementById('root'),
)
// ReactDOM.render(<App />,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
