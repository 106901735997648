import React from 'react'
import GoogleMapReact from 'google-map-react'
import RoomIcon from '@material-ui/icons/Room'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CustomBreadcrumbs from '../components/CustomBreadcrumbs'
import { useLocation } from 'react-router-dom'

// const AnyReactComponent = ({ text }) => <RoomIcon />;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '25em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function SimpleMap(props) {
 
  const { state } = useLocation();

  const defaultProps = {
    center: {
      lat: 5.937795,
      lng: 80.576110,
    },
    zoom: 13,
  }
  const classes = useStyles()

  return (

    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.content}
    >
      <Grid item style={{ marginBottom: '2em' }}>
        <Typography variant="h4">Boarding Location </Typography>
      <CustomBreadcrumbs routeName= {"Boardim Details"} ownerName ={state.owner}/>
      </Grid>
      <Grid item style={{ height: '70vh', width: '70%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAODHNR0-ODunUFFSyz2pk_FWiukwoTFV8' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <RoomIcon lat={state.lat} lng={state.lng} style={{ color: '#DC0000' }} />
        </GoogleMapReact> 
      </Grid>
    </Grid>
  )
}

export default SimpleMap
