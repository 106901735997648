import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import CustomSnackBar from '../components/CustomSnackBar'
import CustomTabPanel from '../components/CustomTabPanel'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '15em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function Verification() {
  const classes = useStyles()
  return (
    <Grid container className={classes.content} alignItems="center" justifyContent='center'>
      <Grid item>
        <CustomTabPanel />
      </Grid>
    </Grid>
  )
}

export default Verification
