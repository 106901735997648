import { createTheme } from '@material-ui/core'

const arcBlue = '#0b72B9'
const arcOrange = '#FFBA60'
const arcGrey = '#868686'

export default createTheme({
  palette: {
    common: {
      blue: arcBlue,
      orange: arcOrange,
    },
    primary: {
      main: arcBlue,
    },
    secondary: {
      main: arcOrange,
    },
  },
  typography: {
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
      color: arcBlue,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '2.5rem',
      color: arcBlue,
    },
    h4: {
      fontSize: '1.75rem',
      color: arcBlue,
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.75rem',
      color: arcBlue,
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
      color: arcBlue,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 300,
      color: arcGrey,
    },
    subtitle2: {
      color: 'white',
      fontWeight: 300,
      fontSize: '1.25rem',
    },
    body1: {
      fontSize: '1.25rem',
      color: arcGrey,
      fontWeight: 300,
    },
  },
  content: {
    flexGrow: 1,
    marginTop: '7em',
    marginLeft: '16em',
    width: '85%',
  },
  headerContainer: {
    marginLeft: '2em',
  },
})


// https://www.permify.co/post/jwt-authentication-in-react