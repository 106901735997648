import http from "../../http-common";

const getAll = () => {
  return http.get("/advertplan");
};

const get = id => {
  return http.get(`/advertplan/${id}`);
};

const create = data => {
  return http.post("/advertplan", data);
};

const update = (id, data) => {
  return http.put(`/advertplan/${id}`, data);
};

const remove = id => {
  return http.delete(`/advertplan/${id}`);
};


const AdvertPlanService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default AdvertPlanService;