import { Box, Tabs, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import StudentVerificationTable from './StudentVerificationTable'
import OwnerVerificationTable from './OwnerVerificationTable'
import PaymentVerificationTable from './PaymentVerificationTable'
import BoardimVerificationTable from './BoardimVerificationTable'

function CustomTabPanel() {

  const { id } = useParams();

  const [value, setValue] = React.useState(id)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            centered
          >
            {/* <Tab label="Student Verification" value="1" style={{marginLeft:'8em'}}/>
            <Tab label="Owner Verification" value="2" style={{marginLeft:'8em'}}/>
            <Tab label="Payment Verrification" value="3" style={{marginLeft:'8em'}}/>
            <Tab label="Boarding Place Verrification" value="4" style={{marginLeft:'8em'}}/> */}

            <Tab label="Student Verification" value="1" style={{marginLeft:'8em'}}/>
            {/* <Tab label="Owner Verification" value="2" style={{marginLeft:'8em'}}/> */}
            <Tab label="Payment Verrification" value="2" style={{marginLeft:'8em'}}/>
            <Tab label="Boarding Place Activation" value="3" style={{marginLeft:'8em'}}/>
          </TabList>
        </Box>
        <TabPanel value="1">
          {/* <StudentVerification /> */}
          <StudentVerificationTable/>
        </TabPanel>
        {/* <TabPanel value="2">
          <OwnerVerification />
          <OwnerVerificationTable/>
        </TabPanel> */}
        <TabPanel value="2">
          {/* <PaymentVerification /> */}
          <PaymentVerificationTable/>
        </TabPanel>
        <TabPanel value="3">
          {/* <BoardimVerification/> */}
          <BoardimVerificationTable/>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default CustomTabPanel
