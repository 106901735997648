import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'

function ImageCarousel({ boardimimages }) {
  var images = []

  boardimimages.map((img) => {
    images = [
      ...images,
      {
        original: img.image_url,
        thumbnail: img.image_url,
        originalHeight: '70%',
        originalWidth: '70%',
      },
    ]
  })

  return (
    <ImageGallery
      items={images}
      thumbnailPosition={'right'}
      showBullets
      autoPlay
      slideInterval={5000}
    />
  )
}

export default ImageCarousel
