import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProfileService from "../services/profileService";


  
export const updateProfileStatus = createAsyncThunk(
  "profile/updateUserVerification",
  async (id) => {
    const res = await ProfileService.updateUserVerification(id);
    return res;
  }
);

export const getProfileById = createAsyncThunk(
  "profile/get",
  async (id) => {
    const res = await ProfileService.get(id);
    return res;
  }
);

export const getAllVerifiedFinder = createAsyncThunk(
  "profile/getAllVerifiedFinder",
  async (id) => {
    const res = await ProfileService.getAllVerifiedFinder();
    return res;
  }
);

export const getAllVerifiedOwner = createAsyncThunk(
  "profile/getAllVerifiedOwner",
  async () => {
    const res = await ProfileService.getAllVerifiedOwner();
    return res;
  }
);

export const getAllNotVerifiedFinder = createAsyncThunk(
  "profile/notverifiedfinder",
  async () => {
    const res = await ProfileService.getAllNotVerifiedFinder();
    return res;
  }
);

export const getAllNotVerifiedOwner = createAsyncThunk(
  "profile/notverifiedowner",
  async () => {
    const res = await ProfileService.getAllNotVerifiedOwner();
    return res;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (id,data) => {
    const res = await ProfileService.update(id,data);
    return res;
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user_data: [],
    verifiedfinder: [],
    verifiedowner: [],
    notverifiedfinder: [],
    notverifiedowner: [],
    userProfile : [],
    isLoading: false,
  },
  extraReducers: {
    [updateProfileStatus.pending]: (state) => {
      state.isLoading = true;  
    },
    [updateProfileStatus.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.user_data = payload['data'];
    },
    [updateProfileStatus.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllVerifiedFinder.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllVerifiedFinder.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.verifiedfinder = payload['data']['data'];
    },
    [getAllVerifiedFinder.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllVerifiedOwner.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllVerifiedOwner.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.verifiedowner = payload['data']['data'];
    },
    [getAllVerifiedOwner.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllNotVerifiedFinder.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotVerifiedFinder.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notverifiedfinder = payload['data']['data'];
    },
    [getAllNotVerifiedFinder.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllNotVerifiedOwner.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllNotVerifiedOwner.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.notverifiedowner = payload['data']['data'];
    },
    [getAllNotVerifiedOwner.rejected]: (state) => {
      state.isLoading = false;
    },
    [getProfileById.pending]: (state) => {
      state.isLoading = true;  
    },
    [getProfileById.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.userProfile = payload['data']['data'][0];
    },
    [getProfileById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
})

export default profileSlice.reducer
