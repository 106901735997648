
export var chartOptions = {
  chart: {
    height: 350,
    type: 'area',
    stacked: false,
  },
  stroke: {
    width: [0, 2, 5],
    curve: 'straight'
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
    },
  },

  fill: {
    opacity: [0.85, 0.25, 1],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },
  labels: [],
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    title: {
      text: 'No of Registartions',
    },
    min: 0,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (y) {
        if (typeof y !== 'undefined') {
          return y.toFixed(0) + ' registartions'
        }
        return y
      },
    },
  },
}

export const PieChartData = [44, 55, 13, 43, 22]
export const PieChartOptions = {
  chart: {
    width: 380,
    type: 'pie',
  },
  labels: [],

  legend: {
    position: 'bottom',
  },
}
