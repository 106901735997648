import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllBoardimPlaces } from '../api/features/boardimPlaceSlice'
import MainMap from '../components/map/MainMap'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginBottom:"1em",
    marginLeft: '19em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
  headerContainer: {
    ...theme.headerContainer,
    [theme.breakpoints.down('md')]: {
      marginLeft: '1em',
    },
  },
}))

function ViewOnMap() {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { boradimplaces, isLoading } = useSelector((state) => ({
    ...state.boardimplace,
  }))

  useEffect(() => {
    dispatch(getAllBoardimPlaces())
  }, [dispatch])


  const locations  = boradimplaces.map(item =>  [{lat : parseFloat(item.boardimplace.BoardimPlace.lat) , lng : parseFloat(item.boardimplace.BoardimPlace.lng)}])


  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.content}
    >
     <Grid item style={{ marginBottom: '2em' }}>
      <Typography variant="h4">Boardim Locations</Typography>
      </Grid>
      <Grid item>
      <MainMap locations = { locations} />
      </Grid> 
    </Grid>
  )
}

export default ViewOnMap
