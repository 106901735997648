import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core'
import { tableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAdvertPlan,
  deleteAdvertPlan,
  createAdvertPlan,
} from '../api/features/advertPlanSlice'
import { boardimPlaceverifyHeader } from '../database/TableHeaders'
import ImageZoom from './ImageZoom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorImg from '../assets/error.png'
import CustomSkelton from './CustomSkelton'
import {
  activateBoardimPlace,
  getAllNotActivatedBoardings,
} from '../api/features/boardimPlaceSlice'
import { Link } from 'react-router-dom'
import ImageAvatar from './ImageAvatar'
import RoomIcon from '@material-ui/icons/Room'
import ImageCarousel from './ImageCarousel'
import Alert from '@material-ui/lab/Alert'
import CustomSnackBar from './CustomSnackBar'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginTop: '1em',
    marginLeft: '6em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function BoardimVerificationTable() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [flag, setFlag] = useState(false)
  const dispatch = useDispatch()

  var { notactivatedboardings, isLoading } = useSelector((state) => ({
    ...state.boardimplace,
  }))

  useEffect(() => {
    dispatch(getAllNotActivatedBoardings())
    setFlag(false)
  }, [])

  if (!Array.isArray(notactivatedboardings)) {
    notactivatedboardings = [notactivatedboardings]
  }

  const studentData = notactivatedboardings.map((o) => ({ ...o }))

  return (
    <Container maxWidth="xl">
      {flag ? (
        <CustomSnackBar msg="verification Success" isOpen={flag} />
      ) : null}
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <>
              <Grid item>
                <CustomSkelton />
              </Grid>
            </>
          ) : (
            <>
              <Grid item style={{ marginBottom: '2em' }}>
                <Typography variant="h4">Boarding Place Activation </Typography>
              </Grid>
              <Grid item>
                <MaterialTable
                  tableIcons={tableIcons}
                  style={{ width: matchesSM ? '25em' : '95em' }}
                  title={''}
                  columns={boardimPlaceverifyHeader.map((c) => ({
                    ...c,
                    tableData: undefined,
                  }))}
                  data={studentData}
                  detailPanel={(rowData) => {
                    return (
                      <>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent={'space-evenly'}
                          style={{ margin: '1em 5em' }}
                        >
                          {rowData.boarding_images.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              <Grid item>
                                <Typography
                                  variant="h5"
                                  style={{ marginBottom: '1em' }}
                                >
                                  Boarding Images
                                </Typography>
                              </Grid>
                              <Grid item>
                                <ImageCarousel
                                  boardimimages={rowData.boarding_images}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item>
                            <Typography
                              variant="h5"
                              style={{ marginTop: '1em' }}
                            >
                              Boarding Details
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={'space-evenly'}
                          >
                            <Grid item>
                              {' '}
                              <ImageAvatar
                                img={
                                  rowData.boardimplace.Owner.user_img_url ==
                                  null
                                    ? ErrorImg
                                    : rowData.boardimplace.Owner.user_img_url
                                }
                              />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems={'flex-start'}
                                style={{ margin: '1em' }}
                              >
                                <>
                                  <Grid item>
                                    <Typography>
                                      {' '}
                                      <b>Owner : </b>
                                      {rowData.boardimplace.Owner.first_name}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      <b>Contact Number : </b>{' '}
                                      {rowData.boardimplace.Owner.tp}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      {' '}
                                      <b>Boardim Type : </b>
                                      {
                                        rowData.boardimplace.BoardimPlace
                                          .preffered_gender
                                      }
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      <b>No of Boarders : </b>
                                      {
                                        rowData.boardimplace.BoardimPlace
                                          .no_of_people
                                      }
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      {' '}
                                      <b>Features :</b>
                                    </Typography>
                                    {/* {rowData.features.map((item, index) => (
                                  <Grid key={index} item container style={{ marginLeft: '2em' }}>
                                    <Grid item>
                                      <FiberManualRecord
                                        style={{
                                          height: '10px',
                                          width: '10px',
                                          marginRight: '0.5em',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="body2">{item}</Typography>
                                    </Grid>
                                  </Grid>
                                ))} */}
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      disabled={
                                        rowData.boarding_images.length === 0
                                      }
                                      variant="contained"
                                      endIcon={<RoomIcon />}
                                      style={{ margin: '1em 0em' }}
                                      color="primary"
                                      onClick={() => {
                                        dispatch(
                                          activateBoardimPlace({
                                            id:
                                              rowData.boardimplace.BoardimPlace
                                                .id,
                                            status: 1,
                                          }),
                                        )
                                        dispatch(getAllNotActivatedBoardings())
                                        setFlag(true)
                                      }}
                                    >
                                      Verify here
                                    </Button>
                                  </Grid>
                                </>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }}
                  options={{
                    headerStyle: {
                      backgroundColor: '#01579b',
                      color: 'white',
                    },
                    paging: false,
                    selection: true,
                    grouping: true,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default BoardimVerificationTable
