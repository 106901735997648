import http from '../../http-common'
import Cookies from 'universal-cookie'

const getAll = () => {
  const token  = localStorage.getItem('access_token');
  return http.get('/user', {
    headers: { authorization: `Bearer ${token}` },
  })
}

const get = (id) => {
  return http.get(`/user/${id}`)
}

const create = (data) => {
  return http.post('/user', data)
}

const login = async (data) => {
  return await http.post('/login', data)
}

const logout = () => {
  return http.get('/logout')
}

const update = (id, data) => {
  return http.put(`/user/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/user/${id}`)
}

const UserService = {
  getAll,
  get,
  create,
  update,
  remove,
  login,
  logout
}

export default UserService
