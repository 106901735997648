import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdvertPlanService from "../services/advertPlanService";

export const getAllAdvertPlan= createAsyncThunk(
  "advertplan/getAll",
  async ( id ) => {
    const res = await AdvertPlanService.getAll();
    return res.data;
  }
);

export const updateAdvertPlan= createAsyncThunk(
  "advertplan/update",
  async ( id , advertplan ) => {
    const res = await AdvertPlanService.update(id , advertplan);
    return res.data;
  }
);

export const deleteAdvertPlan= createAsyncThunk(
  "advertplan/remove",
  async ( id ) => {
    const res = await AdvertPlanService.remove(id);
    return res.data;
  }
);

export const createAdvertPlan= createAsyncThunk(
  "advertplan/create",
  async ( plan ) => {
    const res = await AdvertPlanService.create(plan);
    return res.data;
  }
);

const advertPlanSlice = createSlice({
  name: 'advertplan', 
  initialState: {
    advertplans: [],
    isLoading: false,
    isLoadings: false,
  },
  extraReducers: {
    [getAllAdvertPlan.pending]: (state) => {
      state.isLoading = true;  
    },
    [getAllAdvertPlan.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      state.advertplans = payload['data'];
    },
    [getAllAdvertPlan.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateAdvertPlan.pending]: (state) => {
      state.isLoadings = true;  
    },
    [updateAdvertPlan.fulfilled]: (state, {payload}) => {
      state.isLoadings = false;
    },
    [updateAdvertPlan.rejected]: (state) => {
      state.isLoadings = false;
    },
    [deleteAdvertPlan.pending]: (state) => {
      state.isLoadings = true;  
    },
    [deleteAdvertPlan.fulfilled]: (state, {payload}) => {
      state.isLoadings = false;
    },
    [deleteAdvertPlan.rejected]: (state) => {
      state.isLoadings = false;
    },
  },
})


export default advertPlanSlice.reducer
