import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../services/userService'

export const getAllUsers = createAsyncThunk('user/getAll', async () => {
  const res = await UserService.getAll()
  console.log(res.data)
  return res.data
})

export const loginUser = createAsyncThunk('/login', async (data) => {
  const res = await UserService.login(data)
  return res.data
})

export const logoutUser = createAsyncThunk('/logout', async () => {
  const res = await UserService.logout()
  return res.data
})

export const registerUser = createAsyncThunk('/signUp', async (data) => {
  const res = await UserService.login(data)
  return res.data
})

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    students: [],
    owners: [],
    loginData: [],
    logoutStatus: [],
    isLoading: false,
    isLoing: false,
  },
  reducers: {
    getStudents: (state, action) => {
      state.students = state.users.filter((item) => item.role_id === 1)
    },
    getOwners: (state, action) => {
      state.owners = state.users.filter((item) => item.role_id === 2)
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.isLoading = true
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.users = payload['data']
    },
    [getAllUsers.rejected]: (state) => {
      state.isLoading = false
    },
    [loginUser.pending]: (state) => {
      state.isLoing = true
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isLoing = false
      state.loginData = payload['data']
    },
    [loginUser.rejected]: (state) => {
      state.isLoing = false
    },
    [logoutUser.pending]: (state) => {
      state.isLoading = true
    },
    [logoutUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.logoutStatus = payload
    },
    [logoutUser.rejected]: (state) => {
      state.isLoading = false
    },
  },
})

export const { getStudents, getOwners } = userSlice.actions

export default userSlice.reducer
