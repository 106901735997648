import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { boardimPlaceHeader } from '../database/TableHeaders'
import TableBoardimBody from '../components/TableBoardimBody'
import Table from '../components/Table'
import CustomSkelton from '../components/CustomSkelton'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBoardimPlaces } from '../api/features/boardimPlaceSlice'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    ...theme.content,
    marginLeft: '18em',
    [theme.breakpoints.down('md')]: {
      marginTop: '6em',
      marginLeft: '2em',
    },
  },
}))

function Admin() {
  const classes = useStyles()
  return (


     <Container maxWidth="xl">
      <Grid container className={classes.content}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
         Admin View
        </Grid>
      </Grid> 
    </Container>
  )
}

export default Admin
