import { Box, Card, CardHeader } from '@material-ui/core'
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { PieChartOptions } from '../../database/TestData'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSubViceAmount } from '../../api/features/customSlice'

function AdvertPlanChart() {
  const dispatch = useDispatch()
  const { subviceamount, isLoading } = useSelector((state) => ({
    ...state.custom,
  }))

  useEffect(() => {
    dispatch(getAllSubViceAmount())
  }, [dispatch])

  const amout = subviceamount.map((item) => parseInt(item.amount, 10))
  const datelabel = subviceamount.map((item) => item.name)

  let PieChartData = amout

  PieChartOptions.labels = datelabel

  return (
    <Card style={{ borderRadius: '2em', boxShadow: '1em' }}>
      <CardHeader title={'Advert Plans'} subheader="(+22%) than last year" />
      <Box style={{ margin: '4.5em' }}>
        <ReactApexChart
          type="pie"
          series={PieChartData}
          options={PieChartOptions}
          height={280}
        />
      </Box>
    </Card>
  )
}

export default AdvertPlanChart
